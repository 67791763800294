import React from 'react'
import { ErrorMessage } from '../../components/ErrorMessage'
import { useController } from 'react-hook-form'

export type InputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'size' | 'prefix' | 'type' | 'onChange'
> &
  Partial<{
    wrapClassName: string
    className: string
    name: string
    placeholder: string
    type: string
    errors: string[]
    label: string
    prefix: React.ReactNode
    suffix: React.ReactNode
    control: any
    desc?: string
  }>

  const Input: React.FC<InputProps> = ({
    wrapClassName = '',
    className = '',
    name = '',
    placeholder = '',
    type = 'text',
    children,
    label = '',
    prefix,
    suffix,
    control,
    defaultValue,
    desc,
    ...restProps
  }) => {
    const { field, fieldState } = useController({
      control,
      name,
      defaultValue: defaultValue || '',
      rules: {
        validate: (value) => {
          if (type === 'tel' || name.includes('mobile') || name.includes('phone')) {
            const digitsOnly = value.replace(/\D/g, '');
            if (digitsOnly.length !== 10) {
              return "Mobile number must be exactly 10 digits";
            }
          }
          return true;
        },
        onChange: (event) => {
          if (type === 'tel' || name.includes('mobile') || name.includes('phone')) {
            const value = event.target.value.replace(/\D/g, '').slice(0, 10);
            return value;
          }
          return event.target.value;
        }
      }
    });
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (type === 'tel' || name.includes('mobile') || name.includes('phone')) {
        const value = e.target.value.replace(/\D/g, '').slice(0, 10);
        field.onChange(value);
      } else {
        field.onChange(e);
      }
    };
  
    return (
      <div className="flex flex-col items-end w-full">
        <div className={`${wrapClassName}`}>
          {!!label && label}
          {!!prefix && prefix}
          <input
            className={`${className} bg-transparent border-0 !text-gray-900 placeholder:text-gray-400 `}
            type={type}
            placeholder={placeholder}
            {...field}
            onChange={handleChange}
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!desc && (
          <div className="mt-1 text-[13px] text-gray-500 text-left w-full">
            {desc}
          </div>
        )}
        {!!fieldState.error && (
          <ErrorMessage
            errors={[fieldState.error.message]}
            className="text-right"
          />
        )}
      </div>
    )
  }

export { Input }
