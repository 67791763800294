import { skillsOptions } from 'constant/OptionInstance'
import exp from 'constants'
import * as yup from 'yup'

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter valid email'),
  password: yup.string().required('Password is required'),
})

export const clientContactSchema = yup.object().shape({
  contacts: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Name is required'),
        mobile_no: yup
          .string()
          .required('Mobile Number is required')
          .matches(/^\d{10}$/, 'Mobile Number must be 10 digits'),
        phone_no: yup
          .string()
          .matches(/^\d{10}$/, 'Phone Number must be 10 digits'),
        email: yup
          .string()
          .email('Invalid Email')
          .required('Email is required'),
        skype_id: yup.string(),
        position: yup.string(),
        status: yup.string(),
        primary_contact: yup.boolean(),
      }),
    )
    .min(1, 'At least one contact is required'),
})

export const addBranchSchema = yup.object().shape({
  name: yup.string().required('Branch Name is required'),
  branch_head: yup.string().required('Branch Head is required'),
  address: yup.object().shape({
    line1: yup.string().required('Address Line 1 is required'),
    line2: yup.string().required('Address Line 2 is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    pincode: yup.string(),
  }),
  contact_no: yup.string().required('Phone is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  website: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid Url',
    )
    .required('Please enter website'),
})

export const addProjectSchema = yup.object().shape({
  name: yup.string().required('Project Name is required'),
  client: yup.string().required('Client is required'),
  approver: yup.string().required('Approver is required'),
  about: yup.string().required('Project Description is required'),
  start_date: yup.string().required('Start Date is required'),
  end_date: yup.string().required('End Date is required'),
})

export const requirementSchema = yup.object().shape({
  branch: yup.string().required('Branch is required'),
  // title: yup.string().required('Overview/Job Title is required'),
  skills: yup.array().min(1, 'Skills Required is required'),
  division: yup
    .string()
    .required('Department / Division is required')
    .optional(),
  speaking_language: yup.array().min(1, 'Language (Speaking) is required'),
  writing_langugage: yup.array().min(1, 'Language (Writing) is required'),
  currency: yup.string().required('Currency is required'),
  ecr_ecnr: yup.string().required('ECR or ECNR required'),
  license: yup.string(),
  // contract: yup.number().required('Contract is required'),
  allowances: yup.object().shape({
    food: yup.object().shape({
      type: yup.string(),
      amount: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === '' || originalValue === undefined ? null : value,
        )
        .when('type', {
          is: (type) => type === 'free_food',
          then: (schema) => schema.notRequired(),
          otherwise: (schema) =>
            schema
              .required('Food allowance amount is required')
              .positive('Food allowance amount must be a positive number'),
        }),
    }),
    accomodation: yup.object().shape({
      type: yup.string(),
      amount: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === '' || originalValue === undefined ? null : value,
        )
        .when('type', {
          is: (type) => type === 'free_accomodation',
          then: (schema) => schema.notRequired(),
          otherwise: (schema) =>
            schema
              .required('Accomodation amount is required')
              .positive(
                'Accomodation allowance amount must be a positive number',
              ),
        }),
    }),
    medical: yup.object().shape({
      type: yup.string(),
      amount: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === '' || originalValue === undefined ? null : value,
        )
        .when('type', {
          is: (type) => type === 'free_medical',
          then: (schema) => schema.notRequired(),
          otherwise: (schema) =>
            schema
              .required('Medical amount is required')
              .positive('Medical allowance amount must be a positive number'),
        }),
    }),
    transport: yup.object().shape({
      type: yup.string(),
      amount: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === '' || originalValue === undefined ? null : value,
        )
        .when('type', {
          is: (type) => type === 'free_transport',
          then: (schema) => schema.notRequired(),
          otherwise: (schema) =>
            schema
              .required('Transport amount is required')
              .positive('Transport allowance amount must be a positive number'),
        }),
    }),
  }),
})

export const jdSchema = yup.object().shape({
  order: yup.string().required('Order is required'),
  orderName: yup.string().required('Order Name is required').optional(),
  category: yup.string().required('Job Category is required'),
  position: yup.string().required('Position is required'),
  job_type: yup.string().required('Job Type is required'),
  reports_to: yup.string().required('Job Type is required').optional(),
  religion: yup.string().required('Religion is required'),
  qualification: yup.string().required('Qualification is required'),
  salary: yup.object().shape({
    type: yup.string().required('Salary Type is required'), // Salary type (monthly, hourly, annually)
    amount: yup.object().shape({
      min: yup
        .number()
        .required('Salary Min Range is required')
        .min(0, 'Salary Min Range must be at least 0'), // Minimum salary amount
      max: yup
        .number()
        .required('Salary Max Range is required')
        .moreThan(
          yup.ref('min'),
          'Salary Max Range must be greater than Min Range',
        ), // Maximum salary amount
    }),
  }),
  age: yup.number().required('Age is required'),
  working_hours: yup.string().required('Working Hours is required'),
  overtime: yup.boolean().required('Overtime must be a boolean'),
  Indian_experience: yup.object().shape({
    min: yup
      .number()
      .required('Indian Experience Min Range is required')
      .min(0, 'Indian Experience Min Range must be at least 0') // Allows 0 but not negative numbers
      .typeError('Indian Experience Min Range must be a number'),
    max: yup
      .number()
      .required('Indian Experience Max Range is required')
      .positive('Indian Experience Max Range must be a positive number')
      .moreThan(yup.ref('min'), 'Max must be greater than Min') // Ensures max is greater than min
      .typeError('Indian Experience Max Range must be a number')
      .positive('Indian Experience Max Range must be a positive number')
      .min(
        yup.ref('min'),
        'Maximum experience must be greater than minimum experience',
      ),
  }),
  Gulf_experience: yup.object().shape({
    min: yup
      .number()
      .required('Gulf Experience Min Range is required')
      .positive('Gulf Experience Min Range must be a positive number')
      .min(0, 'Indian Experience Min Range must be at least 0'),
    max: yup
      .number()
      .required('Gulf Experience Max Range is required')
      .positive('Gulf Experience Max Range must be a positive number')
      .min(
        yup.ref('min'),
        'Maximum experience must be greater than minimum experience',
      ),
  }),
  Other_experience: yup.object().shape({
    min: yup
      .number()
      .required('Other Experience Min Range is required')
      .positive('Other Experience Min Range must be a positive number')
      .min(0, 'Indian Experience Min Range must be at least 0'),
    max: yup
      .number()
      .required('Other Experience Max Range is required')
      .positive('Other Experience Max Range must be a positive number')
      .min(
        yup.ref('min'),
        'Maximum experience must be greater than minimum experience',
      ),
  }),
  start_date: yup.date().required('Start Date is required'),
  end_date: yup
    .date()
    .required('End Date is required')
    .min(yup.ref('start_date'), 'End date must be later than the start date'),
})

export const newJDSchema = yup.object().shape({
  sections: yup.array().of(jdSchema).min(1, 'At least one section is required'),
})

export const evaluateValidation = yup.object().shape({
  evaluateValues: yup.object().shape({
    communication_skills: yup
      .number()
      .required('Communication skills is required'),
    technical_proficiency: yup
      .number()
      .required('Technical proficiency is required'),
    problem_solving_ability: yup
      .number()
      .required('Problem solving ability is required'),
    teamwork_collaboration: yup
      .number()
      .required('Teamwork collaboration is required'),
    adaptablity: yup.number().required('Adaptability is required'),
  }),
})

export const sourcingSchema = yup.object().shape({
  project_id: yup.string().required('Project Id is required'),
  job_id: yup.string().required('Requirement Id is required'),
  inputSkills: yup.array().min(1, 'Skills Required is required'),
  minExperience: yup.number().required('Minimum Experience is required'),
  gulfExperience: yup.number().required('Gulf Experience is required'),
  inputLanguages: yup.array().min(1, 'Languages Required is required'),
  mainTrade: yup.string().required('Main Trade is required'),
})

export const employeSchema = yup.object().shape({
  name: yup.string().required('Employee Name is required'),

  address: yup.object().shape({
    addressLine1: yup.string().required('Address Line 1 is required'),
    addressLine2: yup.string().required('Address Line 2 is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    pincode: yup.number().required('Pin Code is required'),
  }),

  reportsTo: yup.string().required('Reports To is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email - Personal is required'),
  employeeEmail: yup
    .string()
    .email('Invalid email address')
    .required('Email - Company is required'),
  mobileNo: yup.string().required('Phone number is required'),
  employeeDepartment: yup.string().required('Employee Department is required'),
  employeeDesignation: yup
    .string()
    .required('Employee Designation is required'),
  status: yup.string().required('Status is required'),
})

export const createCandidate = yup.object().shape({
  firstname: yup.string().required('First Name is required'),
  lastname: yup.string().required('Last Name is required'),
  dob: yup.date().required('Date of Birth is required'),
  gender: yup.string().required('Gender is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  mobile_number: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Must be at least 10 digits')
    .max(12, 'Must not exceed 12 digits')
    .required('Mobile Number is required'),
  nationality: yup.string().required('Nationality is required'),

  passport: yup.object().shape({
    passport_url: yup.string(),
    passport_issue_country: yup.string().required('Place of Issue is required'),
    passport_issue_date: yup
      .date()
      .nullable() // Allow `null`
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      )
      .required('Issue Date is required'),
    passport_expiry_date: yup
      .date()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      )
      .required('Expiry Date is required')
      .test(
        'is-after-issue-date',
        'Expiry Date must be after Issue Date',
        function (value) {
          return value && this.parent.passport_issue_date
            ? value > this.parent.passport_issue_date
            : true
        },
      ),
    passport_type: yup.string().required('Passport Type is required'),
    passport_number: yup.string().required('Passport Number is required'),
  }),
  aadhar_card: yup.object().shape({
    aadhar_card_number: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(12, 'Must be exactly 12 digits')
      .max(12, 'Must be exactly 12 digits')
      .required('Adhar Number is required'),
  }),

  pancard_number: yup.string().required('PAN Number is required'),

  experience: yup.object().shape({
    trade: yup.string().required('Trade is required'),
    Gulf_experience: yup
      .number()
      .required('Gulf Experience is required')
      .min(0, 'Gulf Experience must be a positive number'),
    total_experience: yup
      .number()
      .required('Total Experience is required')
      .min(0, 'Total Experience must be a positive number')
      .test(
        'totalExperienceCheck',
        'Total Experience must be equal or greater than the Gulf Experience',
        function (value) {
          const { Gulf_experience } = this.parent
          return value >= Gulf_experience
        },
      ),
  }),
})

//passwords

export const resetPasswordSchema = yup.object({
  password: yup.string().required('Password is required'),
  newPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})
export const updateCandidateSchema = yup.object().shape({
  // Personal Information
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  mobile_number: yup
    .string()
    .required('Mobile number is required')
    .typeError('Mobile number must be a number'),
  whatsapp_number: yup
    .number()
    .nullable()
    .typeError('Whatsapp number must be a number'), // Optional field

  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),

  Skills: yup.array().required('At least one skill is required'),

  // Contact Information
  contact_relation: yup
    .string()
    .oneOf(
      ['Father', 'Mother', 'Brother', 'Sister', 'Friend', 'Other'],
      'Invalid contact relation',
    )
    .required('Contact relation is required'),
  contact_firstname: yup.string().required('Contact first name is required'),
  contact_lastname: yup.string().required('Contact last name is required'),
  contact_email: yup
    .string()
    .email('Invalid email address')
    .required('Contact email is required'),
  contact_number: yup
    .string()
    .required('Contact mobile number is required')
    .typeError('Contact mobile number must be a number'),

  address: yup.object().shape({
    line1: yup.string().required('Address Line 1 is required'),
    line2: yup.string().required('Address Line 2 is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    pincode: yup.string().required('Pin Code is required'),
  }),
  contact_address: yup.object().shape({
    line1: yup.string().required('Address Line 1 is required'),
    line2: yup.string().required('Address Line 2 is required'),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    pincode: yup.string().required('Pin Code is required'),
  }),
})
