import React, { useEffect, useState } from 'react'
import { ProSidebarProvider } from 'react-pro-sidebar'
import Routes from './Routes'
import 'react-toastify/dist/ReactToastify.css'
import toast, { Toaster } from 'react-hot-toast'
import { setupNotifications, messaging } from 'config/firebase'
import useVisibilityChange from 'helper/useVisibilityChange'
import { useLocation } from 'react-router-dom'

import { onMessage } from '@firebase/messaging'
import NotFound from 'components/NotFound'
import { useQuery } from 'react-query'
import useAccount from 'hooks/useAccount'

const App: React.FC = () => {
  const isForeground = useVisibilityChange()
  const { getUser } = useAccount()
  const { pathname } = useLocation()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const uid = JSON.parse(localStorage.getItem('uid'))
  const { refetch } = useQuery({
    queryKey: ['profile', uid?.uid],
    queryFn: () => getUser(uid?.uid),
    onSuccess: (data) => {
      localStorage.setItem(
        'uid',
        JSON.stringify({
          uid: data.id,
          name: data.name,
          profilePicUrl: data.profilePicUrl,
          userType: data.userType,
          refreshToken: data.refreshToken,
        }),
      )
    },
    enabled: false,
  })

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    if (
      pathname !== '/' &&
      pathname !== '/forgetpassword' &&
      pathname !== '/otp' &&
      pathname !== '/reset-password'
    ) {
      setupNotifications()
    }
    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      toast(`${payload.notification.title}.\n${payload.notification.body}`, {})
    })
  }, [])

  useEffect(() => {
    if (pathname == '/dashboard' && !uid?.refreshToken) {
      refetch()
    }
  }, [])

  if (screenWidth < 130) {
    return (
      <div className="grid w-full h-screen place-items-center">
        <NotFound
          title="Access the app on desktop"
          subtitle="Please use desktop to access the app."
        />
      </div>
    )
  }

  return (
    <ProSidebarProvider>
      <Routes />
      <Toaster />
    </ProSidebarProvider>
  )
}

export default App
