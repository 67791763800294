import { di } from '@fullcalendar/core/internal-common'
import {
  TrueOrFalse,
  countryOption,
  currencyOptions,
  deptOptions,
  evaluateOption,
  genderOptions,
  jobTypeOptions,
  languageOptionsList,
  qualificationOptions,
  religionOptions,
  reportsToOptions,
  reportsToOptions2,
  skillsOptions,
  statusOption,
  tradeOptions,
  reasonOption,
} from './OptionInstance'

export const clientContactInput = [
  { label: 'Name', name: 'name', type: 'text', placeholder: 'Enter Name' },
  {
    label: 'Contact Person / CCP ID',
    name: 'ccp_id',
    type: 'text',
    placeholder: 'Contact Person / CCP ID',
  },
  {
    label: 'mobile',
    name: 'mobile_no',
    type: 'number',
    placeholder: 'Enter Mobile Number',
  },
  {
    label: 'Phone',
    name: 'phone_no',
    type: 'number',
    placeholder: 'Enter Phone Number',
  },
  { label: 'Email', name: 'email', type: 'email', placeholder: 'Enter Email' },
  {
    label: 'skype ID',
    name: 'skype_id',
    type: 'text',
    placeholder: 'Enter Skype ID',
  },
  {
    label: 'position',
    name: 'position',
    type: 'select',
    options: [
      { label: 'CEO', value: 'CEO' },
      { label: 'CTO', value: 'CTO' },
      { label: 'CFO', value: 'CFO' },
      { label: 'CMO', value: 'CMO' },
      { label: 'COO', value: 'COO' },
      { label: 'VP', value: 'VP' },
    ],
    placeholder: 'Enter Position',
  },
  {
    label: 'status',
    name: 'status',
    type: 'select',
    options: [
      { label: 'active', value: 'active' },
      { label: 'inactive', value: 'inactive' },
    ],
    placeholder: 'Enter Status',
  },
]

export const status = [
  { label: 'archive', value: 'archive' },
  { label: 'active', value: 'active' },
  { label: 'ongoing', value: 'ongoing' },
  { label: 'cancelled', value: 'cancelled' },
  { label: 'All', value: undefined },
]

export const RequirementInstance = [
  // {
  //   type: 'text',
  //   name: 'title',
  //   label: 'Overview/Job Title',
  //   required: true,
  // },
  {
    type: 'selectTwo',
    label: 'Food',
    subOption: [
      {
        label: 'Type',
        name: 'allowances.food.type',
        type: 'select',
        options: [
          { label: 'Free Food', value: 'free_food' },
          { label: 'Food Allowance', value: 'food_allowance' },
        ],
      },
      {
        label: 'Amount',
        type: 'number',
        name: 'allowances.food.amount',
        disableField: 'allowances.food.type',
        value: 'free_food',
      },
    ],
  },
  {
    type: 'selectTwo',
    label: 'Medical',
    subOption: [
      {
        label: 'Type',
        name: 'allowances.medical.type',
        type: 'select',
        options: [
          { label: 'Free medical', value: 'free_medical' },
          { label: 'Medical Allowance', value: 'medical_allowance' },
        ],
      },
      {
        label: 'Amount',
        type: 'number',
        disableField: 'allowances.medical.type',
        value: 'free_medical',
        name: 'allowances.medical.amount',
      },
    ],
  },
  {
    type: 'selectTwo',
    label: 'Transport',
    subOption: [
      {
        label: 'Type',
        name: 'allowances.transport.type',
        type: 'select',
        options: [
          { label: 'Free Transport', value: 'free_transport' },
          { label: 'Transport Allowance', value: 'transport_allowance' },
        ],
      },
      {
        label: 'Amount',
        type: 'number',
        disableField: 'allowances.transport.type',
        value: 'free_transport',
        name: 'allowances.transport.amount',
      },
    ],
  },
  {
    type: 'selectTwo',
    label: 'Accomodation',
    subOption: [
      {
        label: 'Type',
        name: 'allowances.accomodation.type',
        type: 'select',
        options: [
          { label: 'Free Accomodation', value: 'free_accomodation' },
          { label: 'Accomodation Allowance', value: 'accomodation_allowance' },
        ],
      },
      {
        label: 'Amount',
        type: 'number',
        disableField: 'allowances.accomodation.type',
        value: 'free_accomodation',
        name: 'allowances.accomodation.amount',
      },
    ],
  },
  {
    type: 'select',
    name: 'ecr_ecnr',
    label: 'ECR or ECNR',
    option: [
      {
        label: 'ECR',
        value: 'ECR',
      },
      {
        label: 'ECNR',
        value: 'ECNR',
      },
      {
        label: 'Both',
        value: 'Both',
      },
    ],
  },
  {
    type: 'select',
    name: 'currency',
    label: 'currency',
    option: currencyOptions,
  },
  {
    type: 'multiSelect',
    name: 'license_country',
    label: 'License Country',
    option: countryOption,
  },
  {
    type: 'select',
    name: 'travel_advisory',
    label: 'PTA (Travel Advisory)',
    option: TrueOrFalse,
  },
  {
    type: 'select',
    name: 'license',
    label: 'License',
    option: [
      { label: 'valid', value: 'valid' },
      { label: 'expired', value: 'expired' },
    ],
  },
]

export const RequirementInstance2 = [
  {
    type: 'dependentSelect',
    parentName: 'category',
    parentLabel: 'Job Category',
    parentOptions: tradeOptions,
    childName: 'position',
    childLabel: 'position',
    childOptionsMap: reportsToOptions,
    parentRequired: true,
    childRequired: true,
    parentPlaceholder: 'Select Job Category',
    childPlaceholder: 'Select Position',
  },

  {
    type: 'multiSelect',
    name: 'skills',
    label: 'Skills Required',
    required: true,
    option: skillsOptions,
  },
  {
    type: 'select',
    name: 'division',
    label: 'Department / Division',
    option: deptOptions,
    required: true,
  },
  {
    type: 'text',
    name: 'location',
    label: 'Location',
  },

  {
    type: 'select',
    name: 'reports_to',
    label: 'Reports to',
    option: reportsToOptions2,
  },
  // {
  //   type: 'select',
  //   name: 'selection_process',
  //   label: 'Selection Process',
  // },
  {
    type: 'date',
    name: 'start_date',
    label: 'Start Date',
    required: true,
  },
  {
    type: 'date',
    name: 'end_date',
    label: 'End Date',
    required: true,
  },
  {
    type: 'select',
    name: 'job_type',
    label: 'Job Type',
    option: jobTypeOptions,
  },
  {
    type: 'select',
    name: 'working_hours',
    label: 'Working Hours',
    option: [
      { label: '8', value: '8' },
      { label: '10', value: '10' },
      { label: '12', value: '12' },
    ],
  },
  {
    type: 'selectTwo',
    label: 'Indian Experience',
    subOption: [
      {
        label: 'Min Range',
        name: 'Indian_experience.min',
      },
      {
        label: 'Max Range',
        name: 'Indian_experience.max',
      },
    ],
  },
  {
    type: 'selectTwo',
    label: 'Gulf Experience',
    subOption: [
      {
        label: 'Min Range',
        name: 'Gulf_experience.min',
      },
      {
        label: 'Max Range',
        name: 'Gulf_experience.max',
      },
    ],
  },
  {
    type: 'selectTwo',
    label: 'Other Experience',
    subOption: [
      {
        label: 'Min Range',
        name: 'Other_experience.min',
      },
      {
        label: 'Max Range',
        name: 'Other_experience.max',
      },
    ],
  },
  {
    type: 'select',
    name: 'overtime',
    label: 'Overtime',
    option: TrueOrFalse,
  },
  {
    type: 'multiSelect',
    name: 'speaking_language',
    label: 'Language (Speaking)',
    option: languageOptionsList,
    required: true,
  },
  {
    type: 'multiSelect',
    name: 'writing_langugage',
    label: 'Language (Writing)',
    option: languageOptionsList,
    required: true,
  },
  // {
  //   type: 'selectTwo',
  //   label: 'age',
  //   subOption: [
  //     {
  //       label: 'Min Range',
  //       name: 'age.min',
  //     },
  //     {
  //       label: 'Max Range',
  //       name: 'age.max',
  //     },
  //   ],
  // },
  {
    type: 'text',
    name: 'age',
    label: 'Age',
    required: true,
  },
  {
    type: 'selectTwo',
    label: 'Salary',
    subOption: [
      {
        label: 'Type',
        name: 'salary.type',
        type: 'select',
        options: [
          { label: 'Monthly', value: 'monthly' },
          { label: 'Hourly', value: 'hourly' },
          { label: 'Annually', value: 'annually' },
        ],
      },
      {
        label: 'Min Range',
        type: 'number',
        name: 'salary.amount.min',
      },
      {
        label: 'Max Range',
        type: 'number',
        name: 'salary.amount.max',
      },
    ],
  },
  {
    type: 'select',
    name: 'religion',
    label: 'Religion',
    option: religionOptions,
  },
  {
    type: 'select',
    name: 'qualification',
    label: 'Qualification',
    option: qualificationOptions,
  },
  {
    type: 'select',
    name: 'other_certificate',
    label: 'Other Certification',
    option: [
      { label: 'ITI', value: 'ITI' },
      { label: 'DIPLOMA', value: 'Diploma' },
      { label: 'OTHER', value: 'Other' },
    ],
  },
]

export const evaluateInstances = [
  {
    name: 'evaluateValues.communication_skills',
    label: 'Communication skills',
    placeholder: 'Enter Communication skills',
    option: evaluateOption,
  },
  {
    name: 'evaluateValues.technical_proficiency',
    label: 'Technical proficiency',
    placeholder: 'Enter Technical proficiency',
    option: evaluateOption,
  },
  {
    name: 'evaluateValues.problem_solving_ability',
    label: 'Problem solving ability',
    placeholder: 'Enter Problem solving ability',
    option: evaluateOption,
  },
  {
    name: 'evaluateValues.teamwork_collaboration',
    label: 'Teamwork collaboration',
    placeholder: 'Enter Teamwork collaboration',
    option: evaluateOption,
  },
  {
    name: 'evaluateValues.adaptablity',
    label: 'Adaptability',
    placeholder: 'Enter Adaptability',
    option: evaluateOption,
  },
]

export const sourcingInput = [
  {
    type: 'select',
    label: 'Main Trade',
    placeholder: 'Select the Main trade',
    name: 'mainTrade',
    option: reportsToOptions.flatMap((item) => Object.values(item)[0]),
  },
  // {
  //   type: 'text',
  //   label: 'Sub-Trade',
  //   placeholder: 'Enter the Sub trade',
  //   name: 'subTrade',
  // },

  {
    label: 'Min Experience',
    type: 'number',
    name: 'minExperience',
    placeholder: 'Min Experience',
  },
  {
    label: 'Gulf Experience',
    type: 'number',
    name: 'gulfExperience',
    placeholder: 'Gulf Experience',
  },
  // {
  //   type: 'selectTwo',
  //   label: 'Min Experience',
  //   subOption: [
  //     {
  //       type: 'select',
  //       // label: 'Country Experience',
  //       placeholder: 'country',
  //       name: 'experienceCountry',
  //       option: countryOption,
  //     },
  //     {
  //       type: 'number',
  //       // label: 'Min Experience',
  //       placeholder: 'min experience',
  //       name: 'minExperience',
  //     },
  //   ],
  // },
  // {
  //   type: 'selectTwo',
  //   label: 'Gulf Experience',
  //   subOption: [
  //     {
  //       type: 'select',
  //       // label: 'Gulf Country Experience',
  //       placeholder: 'country',
  //       name: 'gulfExperienceCountry',
  //       option: countryOption,
  //     },
  //     {
  //       type: 'number',
  //       // label: 'Gulf experience',
  //       placeholder: 'gulf experience',
  //       name: 'gulfExperience',
  //     },
  //   ],
  // },

  {
    type: 'option',
    label: 'Known Language',
    placeholder: 'Select the known language',
    name: 'inputLanguages',
    ismulti: true,
    option: languageOptionsList,
  },
  {
    type: 'option',
    label: 'Skills',
    placeholder: 'Select the skills',
    name: 'inputSkills',
    ismulti: true,
    option: skillsOptions,
  },
]

export const createCandidateInput = [
  {
    title: 'Personal Information',
    inputs: [
      {
        type: 'text',
        label: 'First Name',
        placeholder: 'Enter Your First Name',
        name: 'firstname',
        required: true,
      },
      {
        type: 'text',
        label: 'Last Name',
        placeholder: 'Enter Your Last Name',
        name: 'lastname',
        required: true,
      },
      {
        type: 'date',
        label: 'Date of Birth',
        placeholder: 'Enter Date of Birth',
        name: 'dob',
        required: true,
      },
      {
        type: 'select',
        label: 'Gender',
        placeholder: 'Select your gender',
        name: 'gender',
        required: true,
        option: genderOptions,
      },
      {
        type: 'email',
        label: 'Email',
        placeholder: 'Enter Your Email',
        name: 'email',
        required: true,
      },
      {
        type: 'number',
        label: 'Mobile Number',
        placeholder: 'Enter Your Mobile Number',
        name: 'mobile_number',
        required: true,
      },
      {
        type: 'select',
        label: 'nationality',
        placeholder: 'Select your nationality',
        name: 'nationality',
        option: countryOption,
        required: true,
      },
    ],
  },
  {
    title: 'Identification Information',
    inputs: [
      {
        label: 'place of issue',
        name: 'passport.passport_issue_country',
        type: 'select',
        placeholder: 'Enter Place of Issue',
        option: countryOption,
        required: true,
      },
      {
        type: 'date',
        label: 'issue date',
        placeholder: 'Enter Issue Date',
        name: 'passport.passport_issue_date',
        required: true,
      },
      {
        name: 'passport.passport_expiry_date',
        type: 'date',
        label: 'expiry date',
        placeholder: 'Enter Expiry Date',
        required: true,
      },
      {
        name: 'passport.passport_type',
        type: 'text',
        label: 'passport type',
        placeholder: 'Enter Passport Type',
        required: true,
      },
      {
        name: 'passport.passport_number',
        type: 'text',
        label: 'passport number',
        placeholder: 'Enter Passport Number',
        required: true,
      },
      {
        name: 'aadhar_card.aadhar_card_number',
        type: 'text',
        label: 'aadhar number',
        placeholder: 'Enter Aadhar Number',
        required: true,
      },
      {
        name: 'pancard_number',
        type: 'text',
        label: 'pan number',
        placeholder: 'Enter Pan Number',
        required: true,
      },
    ],
  },
  {
    title: 'Domain Information',
    inputs: [
      {
        name: 'experience.trade',
        type: 'searchSelect',
        label: 'Trade',
        placeholder: 'Enter Trade',
        required: true,
      },
      {
        name: 'experience.Gulf_experience',
        type: 'number',
        label: 'Gulf Experience',
        placeholder: 'Enter Gulf Experience',
        required: true,
      },
      {
        name: 'experience.total_experience',
        type: 'number',
        label: 'Total Experience',
        placeholder: 'Enter Total Experience',
        required: true,
      },
      {
        name: 'Skills',
        type: 'multiSelect',
        label: 'Skills',
        placeholder: 'Enter Skills',
        required: true,
        option: skillsOptions,
      },
    ],
  },
]

export const visitorInput = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    placeholder: 'Enter Name',
    isrequired: true,
  },

  {
    name: 'profession',
    type: 'text',
    label: 'Profession',
    placeholder: 'Enter Profession',
    isrequired: true,
  },
  {
    name: 'contact_number',
    type: 'number',
    label: 'Contact Number',
    placeholder: 'Enter Contact Number',
    isrequired: true,
  },
  {
    name: 'time_in',
    type: 'date',
    label: 'Time In',
    placeholder: 'Enter Time In',
    isrequired: true,
  },
  {
    name: 'time_out',
    type: 'date',
    label: 'Time Out',
    placeholder: 'Enter Time Out',
    isrequired: true,
  },
  {
    name: 'country_of_interest',
    type: 'multiSelect',
    label: 'Country of Interest',
    placeholder: 'Select Country of Interest',
    option: countryOption,
    isrequired: true,
  },
  {
    name: 'yoe',
    type: 'number',
    label: 'Years of Experience',
    placeholder: 'Enter Years of Experience',
    isrequired: true,
  },
  {
    name: 'reason',
    label: 'Reason',
    type: 'select',
    option: reasonOption,
    required: true,
  },
  {
    name: 'description',
    type: 'text',
    label: 'Description',
    placeholder: 'Enter Description',
    isrequired: true,
  },
]

export const callerInput = [
  {
    name: 'callers_name',
    label: 'Caller Name',
    type: 'text',
    placeholder: 'Enter Caller Name',
    required: true,
  },
  {
    name: 'profession',
    label: 'Profession',
    type: 'text',
    placeholder: 'Enter Profession',
    required: true,
  },
  {
    name: 'contact_number',
    label: 'Contact Number',
    type: 'number',
    placeholder: 'Enter Contact Number',
    required: true,
  },
  {
    name: 'age',
    label: 'Caller’s Age',
    type: 'number',
    placeholder: 'Enter Age',
    required: true,
  },
  {
    name: 'country_of_interest',
    label: 'Country of Interest',
    type: 'multiSelect',
    placeholder: 'Select Country of Interest',
    option: countryOption,
    required: true,
  },
  {
    name: 'experience',
    label: 'Years of Experience',
    type: 'number',
    placeholder: 'Enter Experience',
    required: true,
  },
  {
    name: 'reason',
    label: 'Reason To Call',
    type: 'select',
    option: reasonOption,
    required: true,
  },
  {
    name: 'time',
    label: 'Time',
    type: 'date',
    placeholder: 'Enter Time',
    required: true,
    format: 'h:mm aa',
  },
  {
    name: 'description',
    type: 'text',
    label: 'Description',
    placeholder: 'Enter Description',
    isrequired: true,
  },
  {
    name: 'status',
    type: 'select',
    label: 'Status',
    option: statusOption,
    required: true,
  },
]

// update the candidate profile
export const candidateInput = [
  {
    title: 'Personal Information',
    inputs: [
      {
        name: 'firstname',
        label: 'First Name',
        type: 'text',
        placeholder: 'Enter Name',
      },
      {
        name: 'lastname',
        label: 'Last Name',
        type: 'text',
        placeholder: 'Enter Name',
      },
      {
        name: 'middlename',
        label: 'Middle Name',
        type: 'text',
        placeholder: 'Enter Name',
      },
      {
        name: 'mobile_number',
        label: 'Mobile Number',
        type: 'number',
        placeholder: 'Enter Mobile Number',
      },
      {
        name: 'whatsapp_number',
        label: 'Whatsapp Number',
        type: 'number',
        placeholder: 'Enter Whatsapp Number',
      },
      {
        name: 'passport.passport_number',
        label: 'Passport Number',
        type: 'text',
        placeholder: 'Enter the Passport Number',
      },
      {
        name: 'age',
        label: 'age',
        type: 'number',
        placeholder: 'Enter age',
      },
      {
        name: 'gender',
        label: 'gender',
        type: 'text',
        placeholder: 'Enter gender',
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        placeholder: 'Enter Email',
      },
      {
        name: 'position',
        label: 'Current Position',
        type: 'text',
        placeholder: 'Enter Current Position',
      },
      {
        name: 'Skills',
        label: 'Skills',
        type: 'select',
        multi: true,
        option: skillsOptions,
        placeholder: 'Enter Skills',
      },
      {
        type: 'select',
        label: 'Trade',
        placeholder: 'Select the Main trade',
        name: 'experience.trade',
        option: reportsToOptions.flatMap((item) => Object.values(item)[0]),
      },
    ],
  },
  {
    title: 'Candidate Address Information',
    inputs: [
      {
        name: 'address.pincode',
        label: 'candidate Pin Code',
        placeholder: 'Enter Pin Code',
        type: 'number',
        desc: 'Enter pincode first for autofill',
      },
      {
        name: 'address.line1',
        label: 'candidate Address Line 1',
        placeholder: 'Enter Address Line 1',
        type: 'text',
      },
      {
        name: 'address.line2',
        label: 'candidate Address Line 2',
        placeholder: 'Enter Address Line 2',
        type: 'text',
      },
      {
        name: 'address.city',
        label: 'candidate City',
        placeholder: 'Enter City',
        type: 'text',
      },
      {
        name: 'address.state',
        label: 'candidate State',
        placeholder: 'Enter State',
        type: 'text',
      },
      {
        name: 'address.country',
        label: 'candidate Country',
        placeholder: 'Enter Country',
        type: 'text',
      },
    ],
  },

  {
    title: 'Contact Information',
    inputs: [
      {
        name: 'contact_relation',
        label: 'Contact Relation',
        type: 'select',
        option: [
          {
            value: 'Father',
            label: 'Father',
          },
          {
            value: 'Mother',
            label: 'Mother',
          },
          {
            value: 'Brother',
            label: 'Brother',
          },
          {
            value: 'Sister',
            label: 'Sister',
          },
          {
            value: 'Friend',
            label: 'Friend',
          },
          {
            value: 'Other',
            label: 'Other',
          },
        ],
        placeholder: 'Enter Contact Relation',
      },
      {
        name: 'contact_firstname',
        label: 'Contact First Name',
        type: 'text',
        placeholder: 'Enter Contact First Name',
      },
      {
        name: 'contact_lastname',
        label: 'Contact Last Name',
        type: 'text',
        placeholder: 'Enter Contact Last Name',
      },
      {
        name: 'contact_email',
        label: 'Contact Email',
        type: 'email',
        placeholder: 'Enter Contact Email',
      },
      {
        name: 'contact_number',
        label: 'Contact Mobile Number',
        type: 'number',
        placeholder: 'Enter Contact Mobile Number',
      },
    ],
  },
  {
    title: 'Contact Address Information',
    inputs: [
      {
        name: 'contact_address.pincode',
        label: 'Contact Pin Code',
        placeholder: 'Enter Pin Code',
        type: 'number',
        desc: 'Enter pincode first for autofill',
      },
      {
        name: 'contact_address.line1',
        label: 'Contact Address Line 1',
        placeholder: 'Enter Address Line 1',
        type: 'text',
      },
      {
        name: 'contact_address.line2',
        label: 'Contact Address Line 2',
        placeholder: 'Enter Address Line 2',
        type: 'text',
      },
      {
        name: 'contact_address.city',
        label: 'Contact City',
        placeholder: 'Enter City',
        type: 'text',
      },
      {
        name: 'contact_address.state',
        label: 'Contact State',
        placeholder: 'Enter State',
        type: 'text',
      },
      {
        name: 'contact_address.country',
        label: 'Contact Country',
        placeholder: 'Enter Country',
        type: 'text',
      },
    ],
  },
]
