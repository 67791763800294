import { apis, baseURL } from 'service'
import useclient from './useClient'
import { options } from '@fullcalendar/core/preact'
import { PostOffice } from 'types/global'
import axios from 'axios'

export interface optionDetailprops {
  name: string
  project_id: string
  id: string
  title: string
  category?: string
  position?: string
  Gulf_experience?: { min: number; max: number }
  Indian_experience?: { min: number; max: number }
  Other_experience?: { min: number; max: number }
  skills?: string[]
  speaking_language?: string[]
}

export interface response {
  message: string
  data: {
    data: optionDetailprops[]
  }
  stauts: string
}

export interface optionResponse {
  label: string
  value: string
}

export interface query {
  query?: object
}
const useHelpCall = () => {
  const { getAuthHeader } = useclient()

  const getProjectId = async (): Promise<optionResponse[]> => {
    try {
      const { data }: { data: response } = await apis.post(
        `${baseURL}/recruiter/api/v1/project/list`,
        {
          data: {
            options: {
              select: ['id', 'name', 'project_id'],
              pagination: false,
            },
          },
          headers: getAuthHeader(),
        },
      )
      if (!data?.data) {
        return []
      }

      const transformData = data.data.data.map(({ project_id, id, name }) => {
        return {
          label: project_id || name,
          value: id,
        }
      })
      return transformData
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getBranchId = async (query: object = {}) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/branch/list`,
        {
          data: {
            query: query,
            options: {
              select: ['id', 'branch_id', 'name'],
              pagination: false,
            },
          },
          headers: getAuthHeader(),
        },
      )
      if (!data?.data) {
        return []
      }

      const transformData = data.data.data.map(({ branch_id, id, name }) => {
        return {
          label: branch_id || name,
          value: id,
        }
      })
      return transformData
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getRequirementId = async (query: object = {}) => {
    try {
      const { data }: { data: response } = await apis.post(
        `${baseURL}/recruiter/api/v1/job/list`,
        {
          data: {
            query: query,
            options: {
              select: [
                'id',
                'title',
                'category',
                'position',
                'Gulf_experience',
                'Indian_experience',
                'Other_experience',
                'skills',
                'speaking_language',
              ],
              pagination: false,
            },
          },
          headers: getAuthHeader(),
        },
      )
      if (!data?.data) {
        return []
      }

      const transformData = data.data.data.map(
        ({
          title,
          id,
          category,
          position,
          Gulf_experience,
          Indian_experience,
          Other_experience,
          skills,
          speaking_language,
        }) => {
          return {
            label: title || id,
            value: id,
            category: category,
            position: position,
            Gulf_experience: Gulf_experience,
            Indian_experience: Indian_experience,
            Other_experience: Other_experience,
            skills: skills,
            speaking_language: speaking_language,
          }
        },
      )
      return transformData
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getPiplineId = async (
    type: string = 'hiring',
  ): Promise<optionResponse[]> => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/pipeline/list`,
        {
          data: {
            query: {
              pipeline_type: type,
            },
            options: {
              select: ['name', 'id'],
              pagination: false,
            },
          },
          headers: getAuthHeader(),
        },
      )
      if (!data?.data) {
        return []
      }
      const transformData = data.data.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }))
      return transformData
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getUsers = async (usersType: string[]) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/user/list`,
        {
          headers: getAuthHeader(),
          data: {
            query: {
              userType: usersType,
            },
            options: {
              select: ['name', 'id', 'userType', 'email'],
              pagination: false,
            },
          },
        },
      )
      if (!data?.data) {
        return []
      }
      const transformData = data.data.data.map(({ name, id, email }) => ({
        label: name || email,
        value: id,
      }))
      return transformData
    } catch (error) {
      console.error('getusers', error)
      throw error
    }
  }

  const sendBulkEmail = async (data: object) => {
    try {
      await apis.post(`${baseURL}/recruiter/api/v1/candidate/sendBulkEmail`, {
        data: data,
        headers: getAuthHeader(),
      })
    } catch (error) {
      console.error('sendBulkEmail', error)
      throw error
    }
  }

  const sendBulkWhatsapp = async (data: object) => {
    try {
      await apis.post(
        `${baseURL}/recruiter/api/v1/candidate/sendBulkWhatsapp`,
        {
          data: data,
          headers: getAuthHeader(),
        },
      )
    } catch (error) {
      console.error('sendBulkWhatsapp', error)
      throw error
    }
  }

  const headerSearch = async (searchQuery: string) => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/searchall?searchstr=${searchQuery}`,
        {
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error('headerSearch', error)
      throw error
    }
  }

  //get the country and state name by the pincode.
  const getCountryState = async (pincode: number): Promise<PostOffice> => {
    try {
      const { data } = await axios.get(
        `https://api.postalpincode.in/pincode/${pincode}`,
      )
      const address = data[0].PostOffice[0]
      return {
        addressLine1: address.Name + ' ' + address.BranchType,
        addressLine2: address.District + ',' + address.State,
        ...address,
      }
    } catch (error) {
      console.error('getCountryState', error)
      throw error
    }
  }

  // archive client, order , project
  const archive = async ({
    type,
    id,
  }: {
    type: 'client' | 'order' | 'project'
    id: string
  }) => {
    const url = {
      client: '/recruiter/api/v1/client/archiveClient',
      order: '/recruiter/api/v1/order/archiveOrder',
      project: 'recruiter/api/v1/project/archiveProject',
    }
    try {
      await apis.get(baseURL + url[type] + `/${id}`, {
        headers: getAuthHeader(),
      })
      return 'archived request sent successfully'
    } catch (error) {
      console.error('archive', error)
      throw error
    }
  }

  return {
    getProjectId,
    getRequirementId,
    getPiplineId,
    getBranchId,
    getUsers,
    sendBulkEmail,
    sendBulkWhatsapp,
    headerSearch,
    getCountryState,
    archive,
  }
}

export default useHelpCall
