import toast from 'react-hot-toast'
import { apis, baseURL } from 'service'
import { postLogin } from 'service/Api/Login'
import Cookies from 'universal-cookie'
import useclient from './useClient'
import { User } from 'types/global'
import { setupNotifications } from 'config/firebase'
interface Session {
  email: string
  password: string
}

const useAccount = () => {
  const cookies = new Cookies()
  const { getAuthHeader } = useclient()
  const createSession = async (credentials: Session) => {
    try {
      const { data: session } = await postLogin({ data: credentials })
      localStorage.setItem(
        'uid',
        JSON.stringify({
          uid: session.data.id,
          name: session.data.name,
          profilePicUrl: session.data.profilePicUrl,
          userType: session.data.userType,
          refreshToken: session.data.refreshToken,
        }),
      )
      cookies.set('authToken', session.data.token, {
        sameSite: 'strict',
        path: '/',
        expires: new Date(new Date().getTime() + 3 * 60 * 60 * 1000), // 3 hours from the current date
        secure: true,
        //httpOnly: true,
      })
      if (session.data?.fcmToken) {
        cookies.set('fcm', session.data.fcmToken, {
          sameSite: 'strict',
          path: '/',
          secure: true,
        })
        await Notification.requestPermission()
      } else {
        setupNotifications()
      }
      toast.success('login successfully')
      return true
    } catch (error) {
      console.error(error)
      toast.error(
        error?.response?.data?.message ||
          'something went wrong.Try again later',
      )
      return false
    }
  }

  const getUser = async (uid: string): Promise<User> => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/user/${uid}`,
        {
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error('error getting the user', error)
      throw error
    }
  }

  const updateProfile = async (data: any) => {
    try {
      const { data: response } = await apis.put(
        `${baseURL}/recruiter/api/v1/user/update-profile-image`,
        {
          data,
          headers: {
            'Content-Type': 'multipart/form-data',
            ...getAuthHeader(),
          },
        },
      )
      return response.data
    } catch (error) {
      console.error('error updating the user', error)
      throw error
    }
  }

  //forgetPassword section
  const sendOpt = async (email: string) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/auth/forgot-password`,
        {
          data: email,
        },
      )
      return data
    } catch (error) {
      console.error('error sending the otp', error)
      throw error
    }
  }

  const verifyOtp = async (otp) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/auth/validate-otp`,
        {
          data: { otp },
        },
      )
      return data
    } catch (error) {
      console.error('error verifying the otp', error)
      throw error
    }
  }

  const resetPassword = async (data) => {
    try {
      const { data: response } = await apis.put(
        `${baseURL}/recruiter/auth/reset-password`,
        {
          data,
        },
      )
      return response.data
    } catch (error) {
      console.error('error resetting the password', error)
      throw error
    }
  }

  const getLoggedInUser = async () => {
    try {
      const { data } = await apis.get(`${baseURL}/recruiter/api/v1/user/me`, {
        headers: getAuthHeader(),
      })
      return data.data
    } catch (error) {
      console.error('Error fetching logged-in user:', error)
      throw error
    }
  }

  return {
    createSession,
    getUser,
    updateProfile,
    sendOpt,
    verifyOtp,
    resetPassword,
    getLoggedInUser,
  }
}

export default useAccount
