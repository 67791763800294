import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Img, Text } from 'components'
import toast from 'react-hot-toast'
import useAccount from 'hooks/useAccount'
import { useMutation } from 'react-query'
import Cookies from 'universal-cookie'

const ForgotOtp = () => {
  const navigate = useNavigate()
  const { verifyOtp } = useAccount()
  const [otp, setOtp] = useState(['', '', '', ''])
  const inputRefs = useRef([])
  const cookies = new Cookies()

  const optHandler = useMutation({
    mutationFn: verifyOtp,
    mutationKey: ['verifyOtp'],
    onError: (error: any) => {
      toast.error(error?.response?.data?.message, { id: 'verifyOtp' })
    },
    onSuccess: () => {
      toast.success('OTP verified successfully', { id: 'verifyOtp' })
      navigate('/reset-password')
    },
    onMutate: () => {
      toast.loading('Verifying the OTP...', { id: 'verifyOtp' })
    },
  })

  const optVerify = () => {
    const otpString = otp.join('')
    optHandler.mutate(otpString)
    cookies.set('c', otpString)
  }

  const handleOtpChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, '')
    if (!value) return

    const newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp]

      // Clear current field
      newOtp[index] = ''

      // Move focus to previous field only if current field is empty
      if (index > 0 && otp[index] === '') {
        inputRefs.current[index - 1]?.focus()
      }

      setOtp(newOtp)
    }
  }

  const handlePaste = (e) => {
    e.preventDefault()
    const pasteData = e.clipboardData
      .getData('text')
      .slice(0, otp.length)
      .split('')
    setOtp([...pasteData, ...Array(otp.length - pasteData.length).fill('')])
    inputRefs.current[pasteData.length - 1]?.focus()
  }

  return (
    <div className="bg-blue-A200 min-h-[100vh] flex sm:flex-col md:flex-col flex-row sm:gap-10 md:gap-10 items-center justify-between md:justify-center md:px-10 px-20 sm:px-5 w-auto sm:w-full md:w-full">
      <Img
        className="h-full w-[418px] md:hidden"
        src="images/img_image.svg"
        alt="image"
      />
      <div className="bg-white-A700 flex flex-col gap-[15px] items-center justify-center pb-20 pt-10 md:px-5 px-[30px] rounded-[75px] shadow-bs2 w-[547px] sm:w-full">
        <div className="flex flex-col font-montserrat gap-[15px] items-center justify-center w-full">
          <div className="h-[89px] md:h-[50px] w-20 sm:w-full flex items-center justify-center">
            <Img
              className="object-contain w-full h-full"
              src="images/img_logo.png"
              alt="logo"
            />
          </div>
          <Text className="font-semibold text-2xl md:text-[22px] text-black-900 sm:text-xl w-auto">
            Ambe Consultancy Pvt. Ltd.
          </Text>
        </div>

        <div className="flex flex-col items-start justify-center w-full gap-2 pt-5 font-poppins">
          <Text className="w-auto text-xl font-medium text-black-900">
            Password Reset
          </Text>
          <Text className="w-auto text-sm font-medium text-gray-900_a8">
            We sent a code to your registered email
          </Text>
        </div>

        {/* OTP Input Fields */}
        <div className="w-full h-[82px] px-10 flex justify-between items-center">
          {otp.map((value, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              value={value}
              name={index.toString()}
              maxLength={1}
              onChange={(e) => handleOtpChange(e, index)}
              onKeyDown={(e) => handleBackspace(e, index)}
              onPaste={handlePaste}
              type="text"
              className="flex justify-center items-center text-[61px] font-poppins font-[400] px-[12px] py-[15px] border border-solid h-[82px] rounded-[4px] w-[69px] border-[#809FA9] text-center"
            />
          ))}
        </div>

        <Button
          className="common-pointer bg-blue-A200 border-[3px] border-blue-A200 border-solid cursor-pointer font-poppins font-[500] py-[18px] rounded-[10px] text-center text-white-A700 text-[16px] w-full"
          onClick={optVerify}
        >
          Verify
        </Button>

        <div className="flex justify-start w-full">
          <Button
            type="button"
            className="cursor-pointer text-neutral-900 text-base font-medium font-['Poppins'] leading-loose"
            onClick={() => navigate('/')}
          >
            Back to login
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ForgotOtp
