import { createColumnHelper } from '@tanstack/react-table'
import Spinner from 'Common/spinner'
import { ReactTable, Text, Button, Img, Line } from 'components'
import {
  Drop,
  Dropdown,
  DropdownList,
  DropdownTrigger,
} from 'components/Dropdown'
import Cell from 'components/Table/Cell'
import Header from 'components/Table/Header'
import useFilter from 'hooks/useFilter'
import useReception from 'hooks/useReception'
import { useMemo } from 'react'
import toast from 'react-hot-toast'
import { CiFilter } from 'react-icons/ci'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Search from 'components/Search'
import { cn, Pagination } from 'utils'
import { useDebouncedCallback } from 'use-debounce'
import PlusButton from 'components/PlusButton'
import AddVisitor from 'modals/AddVisitor'
interface VisitorLogProps {
  date: string
  time_in: string
  time_out: string
  name: string
  reason: string
  contact_number: string
}

const section = [
  // { name: undefined, label: 'All Logs' },
  // { name: 'Time In', label: 'Time In' },
  // { name: 'Time Out', label: 'Time Out' },
]

const status = [
  // { label: 'All Logs', value: undefined },
  // { label: 'Time In', value: 'Time In' },
  // { label: 'Time Out', value: 'Time Out' },
]
const VisitorLog = () => {
  const { getVisitorLog } = useReception()
  const { filterOption, refetchPaginationData, setPaginatorData, setQuery } =
    useFilter()
  const navigate = useNavigate()
  //getting the visitor log
  const { data: visitorLog, isLoading } = useQuery({
    queryKey: ['visitorLog', filterOption],
    queryFn: () => getVisitorLog(filterOption),
    onSuccess: (data) => {
      if (data?.paginator) {
        setPaginatorData(data?.paginator)
      }
    },
    onError: (error) => {
      toast.error('Error while fetching the visitor log')
      navigate('/dashboard')
    },
  })

  const statusHandler = (value: string) => {
    setQuery({
      status: value,
    })
  }
  /**
   * Debounced search function for projects.
   * @param value - The search value.
   */
  const searchProject = useDebouncedCallback((value: string) => {
    setQuery({
      name: value == '' ? undefined : value,
    })
  }, 700)

  const logTable = useMemo(() => {
    const columnHelper = createColumnHelper<VisitorLogProps>()

    return [
      columnHelper.accessor('time_in', {
        header: () => <Header data="Date" />,
        cell: (row) => (
          <Cell data={new Date(row.getValue()).toLocaleDateString()} />
        ),
      }),
      columnHelper.accessor('time_in', {
        header: () => <Header data="Time in" />,
        cell: (row) => (
          <Cell data={new Date(row.getValue()).toLocaleTimeString()} />
        ),
      }),
      columnHelper.accessor('time_out', {
        header: () => <Header data="Time out" />,
        cell: (row) => (
          <Cell data={new Date(row.getValue()).toLocaleTimeString()} />
        ),
      }),
      columnHelper.accessor('name', {
        header: () => <Header data="Visitor's Name" />,
        cell: (row) => <Cell data={row.getValue()} />,
      }),
      columnHelper.accessor('reason', {
        header: () => <Header data="Reason" />,
        cell: (row) => <Cell data={row.getValue()} />,
      }),
      columnHelper.accessor('contact_number', {
        header: () => <Header data="Contacted Person" />,
        cell: (row) => <Cell data={row.getValue()} />,
      }),
    ]
  }, [visitorLog])

  if (isLoading) return <Spinner />

  return (
    <div className="flex flex-col gap-[15px] items-start justify-start pb-10 pt-5 px-5 w-full">
      <div className="flex sm:flex-col flex-row gap-2.5 items-center justify-between  mx-auto md:px-5 w-full">
        <Text
          className="w-auto text-xl text-gray-900 uppercase "
          size="txtPoppinsMedium20Gray900"
        >
          Visitor Log
        </Text>
      </div>
      <div className="flex sm:flex-col flex-row gap-2.5 items-center justify-between  mx-auto md:px-5 w-full">
        <div className="flex flex-col items-start justify-start w-auto sm:flex-1 sm:w-full">
          <div className="justify-start items-center gap-[15px] inline-flex">
            {section.map((subSection) => (
              <Button
                onClick={() => {
                  statusHandler(subSection.name)
                }}
                className="cursor-pointer relative px-4 py-[7px] rounded-[3px] justify-center items-center gap-2.5 flex flex-col"
                key={subSection.name}
              >
                <div
                  className={`${
                    filterOption.query?.status === subSection.name
                      ? 'text-blue-500 font-medium'
                      : 'text-[#858589]'
                  } text-[15px]  font-['Poppins']`}
                >
                  {subSection.label}
                </div>
                {filterOption.query?.status === subSection.name && (
                  <div className="absolute bottom-0 h-[3.5px] rounded-[2px] bg-blue-500 w-full"></div>
                )}
              </Button>
            ))}
          </div>
          <Line className="w-full h-px bg-blue_gray-900_19" />
        </div>

        <div className="common-pointer flex flex-row gap-[20px] items-center justify-start w-auto">
          <div className="inline-flex items-center self-stretch justify-between">
            <div className="flex items-center justify-end w-full gap-3">
              {status.length > 1 && (
                <Dropdown>
                  <DropdownTrigger className="flex items-center justify-center gap-2 px-4 py-2 text-[#191C1DB2] border-2 border-[#191C1DB2] capitalize rounded-md bg-white-A700">
                    <CiFilter className="text-2xl text-black-900" />{' '}
                    {filterOption.query?.status || 'FILTER'}
                  </DropdownTrigger>
                  <DropdownList>
                    {status.map((status) => (
                      <Drop
                        className="self-stretch px-3.5 py-3 bg-[#FFF] rounded-tl rounded-tr border-t border-stone-50 justify-start items-start gap-2 inline-flex"
                        key={status.value}
                        onClick={() => statusHandler(status.value)}
                      >
                        <div
                          className={cn(
                            'text-xs font-semibold font-poppins leading-[18px] uppercase',
                            status.value == 'draft' && 'text-purple-500',
                            status.value == 'active' && 'text-green-500',
                            status.value == 'decline' && 'text-red-500',
                          )}
                        >
                          {status.label}
                        </div>
                      </Drop>
                    ))}
                  </DropdownList>
                </Dropdown>
              )}
              <Button className="flex items-center cursor-pointer bg-white-A700 text-[#191C1DB2] px-4 py-2 rounded-lg border-2 border-[#191C1DB2]">
                <Img className="mr-3" src="/images/bytesize_export.svg" />
                Export
              </Button>
              <AddVisitor>
                <PlusButton
                  title="Add Visitor"
                  className="flex items-center cursor-pointer bg-blue-700 text-white-A700 px-4 py-2 rounded-lg border-2 border-blue-700"
                />
              </AddVisitor>
            </div>
          </div>
        </div>
      </div>
      <Search
        searchFunction={searchProject}
        defaultValue={filterOption.query?.name as string}
        wrapClassName="max-w-[476px]"
      />
      <div className="overflow-auto w-full h-auto bg-[#FFF] rounded-[17.34px] shadow flex-col justify-start items-start gap-[11.56px] flex">
        <ReactTable
          columns={logTable}
          data={visitorLog?.data || []}
          rowClass={'border-b border-gray-200'}
          headerClass="bg-[#FAFBFB] h-[70px]  rounded-[17.34px]"
          isLoading={isLoading}
        />
        <div className="overflow-auto flex  gap-[20px] w-full justify-between px-[24px] pt-[12px] pb-[16px]">
          <button
            disabled={!filterOption.options.hasPrevPage}
            onClick={() => refetchPaginationData(filterOption.options.page - 1)}
            className={cn(
              'w-[114px] h-9 px-3.5 py-2 bg-[#FFF] rounded-lg  border border-gray-300 justify-center items-center gap-2 inline-flex disabled:bg-[#F3F3F8] disabled:opacity-50',
            )}
          >
            <div className="relative w-5 h-5">
              <img src="images/arrow-left.svg" alt="arrow" />
            </div>
            <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight">
              Previous
            </div>
          </button>

          <Pagination
            currPage={filterOption.options.page}
            pageCount={filterOption.options.pageCount}
            onPageChange={refetchPaginationData}
          />

          <button
            disabled={!filterOption.options.hasNextPage}
            onClick={() => refetchPaginationData(filterOption.options.page + 1)}
            className={cn(
              'w-[114px] h-9 px-3.5 py-2 bg-[#FFF] rounded-lg  border border-gray-300 justify-center items-center gap-2 inline-flex disabled:bg-[#F3F3F8] disabled:opacity-50',
            )}
          >
            <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight">
              Next
            </div>
            <div className="relative w-5 h-5 rotate-180">
              <img src="images/arrow-left.svg" alt="arrow" />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default VisitorLog
