import React, { useEffect, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { cn, Pagination } from 'utils'
import { ReactTable, Text } from 'components'
import { useQuery } from 'react-query'
import useSourcing from 'hooks/useSourcing'
import Cell from 'components/Table/Cell'
import Header from 'components/Table/Header'
import Search from 'components/Search'
import useSourcingContext from 'context/SourcingContext'
import NotFound from 'components/NotFound'
import { useNavigate } from 'react-router-dom'
import useFilter from 'hooks/useFilter'
import { useDebouncedCallback } from 'use-debounce'

interface outreachTable {
  project_id: string
  job_title: string
  sourced_by: string
  cv_sourced_length: number
  outreached: number
  _id: string
  job_id: string
}

const OutReach = () => {
  const { getSourcingHistory } = useSourcing()
  const { setCurrentOutreach } = useSourcingContext()
  const { filterOption, refetchPaginationData, setPaginatorData, setQuery } =
    useFilter()
  const navigate = useNavigate()
  const [noData, setNoData] = useState(false)

  const sourcingHistory = useQuery({
    queryKey: ['sourcingHistory', filterOption],
    queryFn: () => getSourcingHistory(filterOption),
    onSuccess: (data) => {
      if (data?.paginator) {
        setPaginatorData(data.paginator)
      }
    },
  })
  const search = useDebouncedCallback((value: string) => {
    setQuery({
      name: value == '' ? undefined : value,
    })
  }, 700)

  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<outreachTable>()
    return [
      tableColumnHelper.accessor('project_id', {
        cell: (info) => <Cell data={info.getValue()} TextClass="uppercase" />,
        header: (info) => <Header data="Project Id" />,
      }),
      tableColumnHelper.accessor('job_id', {
        cell: (info) => <Cell data={info.getValue()} TextClass="uppercase" />,
        header: (info) => <Header data="Job Id" />,
      }),
      tableColumnHelper.accessor('job_title', {
        cell: (info) => (
          <Cell data={info.getValue()} TextClass="text-blue-700 font-medium" />
        ),
        header: (info) => <Header data="Requirement" />,
      }),
      tableColumnHelper.accessor('sourced_by', {
        cell: (info) => <Cell data={info.getValue()} />,
        header: (info) => <Header data="sourced By" />,
      }),
      tableColumnHelper.accessor('cv_sourced_length', {
        cell: (info) => (
          <Cell
            data={String(info.getValue())}
            TextClass="ml-[24px] text-center border-2 border-[#3FB73D] bg-[#E9FFEA] text-[#3FB73D] py-[3px] px-3 rounded-md text-sm font-medium font-roboto"
          />
        ),
        header: (info) => <Header data="CV Sourced" />,
      }),
      tableColumnHelper.accessor('outreached', {
        cell: (info) => <Cell data={String(info.getValue())} />,
        header: (info) => <Header data="Reach" />,
      }),
      tableColumnHelper.accessor('_id', {
        cell: (info) => (
          <div className="w-[170px] h-[72px] px-6 py-4  flex-col justify-center items-start inline-flex  ">
            <Text
              className="common-pointer pb-[30px] pt-[34px] text-blue-A200 text-center text-sm"
              size="txtRobotoRomanRegular14BlueA200"
              onClick={() => navigate(`/outreach/${info.getValue()}`)}
            >
              outreach &gt;&gt;
            </Text>
          </div>
        ),
        header: (info) => <Header data="action" />,
      }),
    ]
  }, [sourcingHistory?.data])

  useEffect(() => {
    if (sourcingHistory?.data?.data?.length === 0) {
      setNoData(true)
    } else {
      setNoData(false)
    }
  }, [sourcingHistory])

  return (
    <>
      <div className="flex sm:flex-col flex-row gap-2.5 items-center justify-between  mx-auto md:px-5 w-full">
        <Text
          className="w-auto text-xl text-gray-900 capitalize"
          size="txtPoppinsMedium20Gray900"
        >
          CV Pool For Outreach
        </Text>
        <Search
          wrapClassName="max-w-[300px]"
          searchFunction={search}
          defaultValue={(filterOption.query?.name as string) || ''}
        />
      </div>
      <div className="overflow-auto w-full h-auto bg-[#FFF] rounded-[17.34px] shadow flex-col justify-start items-start gap-[11.56px] flex">
        {noData ? (
          <div className="grid w-full place-items-center">
            <NotFound
              title="No sourcing history"
              subtitle="No history at the moment"
            />
          </div>
        ) : (
          <div className="w-full">
            <ReactTable
              columns={tableColumns}
              data={sourcingHistory.data?.data || []}
              headerClass="bg-[#FAFBFB] h-[70px]  rounded-[17.34px] border-b"
              rowClick={setCurrentOutreach}
              isLoading={sourcingHistory.isLoading}
            />
            <div className="overflow-auto flex  gap-[20px] w-full justify-between px-[24px] pt-[12px] pb-[16px]">
              <button
                disabled={!filterOption.options.hasPrevPage}
                onClick={() =>
                  refetchPaginationData(filterOption.options.page - 1)
                }
                className={cn(
                  'w-[114px] h-9 px-3.5 py-2 bg-[#FFF] rounded-lg  border border-gray-300 justify-center items-center gap-2 inline-flex disabled:bg-[#F3F3F8] disabled:opacity-50',
                )}
              >
                <div className="relative w-5 h-5">
                  <img src="images/arrow-left.svg" alt="arrow" />
                </div>
                <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight">
                  Previous
                </div>
              </button>

              <Pagination
                currPage={filterOption.options.page}
                pageCount={filterOption.options.pageCount}
                onPageChange={refetchPaginationData}
              />

              <button
                disabled={!filterOption.options.hasNextPage}
                onClick={() =>
                  refetchPaginationData(filterOption.options.page + 1)
                }
                className={cn(
                  'w-[114px] h-9 px-3.5 py-2 bg-[#FFF] rounded-lg  border border-gray-300 justify-center items-center gap-2 inline-flex disabled:bg-[#F3F3F8] disabled:opacity-50',
                )}
              >
                <div className="text-slate-700 text-sm font-medium font-['Inter'] leading-tight">
                  Next
                </div>
                <div className="relative w-5 h-5 rotate-180">
                  <img src="images/arrow-left.svg" alt="arrow" />
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default OutReach
