import { createCandidateInput } from 'constant/inputInstance'
import ModalBase from 'modals/BaseModal'
import { Datepicker, Input, SelectBox, Text } from 'components'
import { Controller, useForm } from 'react-hook-form'
import { createCandidate as candidateValidation } from 'utils/validation'
import { yupResolver } from '@hookform/resolvers/yup'
import useCandidate from 'hooks/useCandidate'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import VerifyCandidate from 'modals/VerifyCandidate'
import { useEffect, useState } from 'react'
import { cn } from 'utils'
import CreatableSelect from 'react-select/creatable'
import { tradeOptions } from 'constant/OptionInstance'

// import mrzScanner from 'mrz-scan'
const CreateCandidate = ({ children }) => {
  const { createCandidate } = useCandidate()
  const [passportPreview, setPassportPreview] = useState<string | null>(null)
  const [pdfFileName, setPdfFileName] = useState<string | null>(null)
  const [createSuccess, setCreateSuccess] = useState<boolean | null>(false)

  const candidateHandler = useMutation({
    mutationKey: ['createCandidate'],
    mutationFn: createCandidate,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
    onSuccess: () => {
      toast.success('Candidate added')
      setCreateSuccess(true)
      form.reset()
    },
  })

  const form = useForm({
    resolver: yupResolver(candidateValidation), // Fix the schema reference
    defaultValues: {
      passport: {
        passport_issue_country: '', // String fields remain empty by default
        passport_issue_date: undefined, // Use `undefined` instead of `null`
        passport_expiry_date: undefined,
        passport_type: '',
        passport_number: '',
      },
    },
  })

  useEffect(() => {
    if (form.formState.errors) {
      toast.error('Please enter Valid information.')
    }
  }, [form.formState.isSubmitted])

  const onSubmit = async (data) => {
    console.log('Data submitting', data)
    await toast.promise(candidateHandler.mutateAsync(data), {
      loading: 'loading...',
      success: 'Candidate added',
      error: undefined,
    })
  }

  // useEffect(() => {
  //   const pass = async () => {
  //     const passportFile = form.getValues('passportfile')[0]
  //     if (passportFile) {
  //       const result = await mrzScanner(Buffer.from(passportFile, 'base64'))
  //       console.log(result)
  //     }
  //   }

  //   if (form.getValues('passportfile').length > 0) {
  //     pass()
  //   }
  // }, [form.watch('passportfile')])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    if (file.type.startsWith('image/')) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPassportPreview(reader.result as string)
        setPdfFileName('') // Clear PDF filename if an image is uploaded
      }
      reader.readAsDataURL(file)
    } else if (file.type === 'application/pdf') {
      setPassportPreview('') // Clear image preview if a PDF is uploaded
      setPdfFileName(file.name)
    }
  }

  const issueDate = form.watch('passport.passport_issue_date')

  return (
    <ModalBase
      triggerComponent={children}
      modalClass="max-w-[1500px]"
      childrenClass="pt-0 p-0 md:px-0"
      acceptFunctions={form.handleSubmit(onSubmit)}
      modalSuccess={createSuccess}
    >
      <div>
        <div className=" fixed bg-white-A700 w-full z-50 mb-4 max-w-[1496px] pt-10   shadow px-10">
          <Text
            className="flex items-center w-auto gap-3 text-[28px] leading-[36px] text-[#191C1D] capitalize"
            size="txtPoppinsMedium20Gray900"
          >
            Create Candidate
          </Text>

          <div className="my-5 font-medium font-['Poppins'] leading-tight flex justify-between text-[#666666] text-[16px]">
            Before creating the candidate please check if exist or not{' '}
            <VerifyCandidate>
              <span className="bg-[#4A7FDF] text-[#FFFFFF] text-[12px] leading-[14px] font-medium py-2 px-3 rounded-[8px] mr-10">
                Check candidate now
              </span>
            </VerifyCandidate>
          </div>
        </div>
        <div className="flex w-full gap-3 mt-[120px] px-5">
          {' '}
          {/* candidate infromation */}
          <div
            className="py-[30px] px-[20px] flex flex-col gap-[40px] rounded-[10px] bg-[#FFF] w-full h-auto "
            // onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-[20px] ">
              {createCandidateInput.map((inputs, index) => (
                <div key={index}>
                  <div className="text-neutral-900 text-[15px] font-semibold font-['Poppins'] leading-tight py-4 capitalize">
                    {inputs.title}
                  </div>
                  <div
                    className="grid gap-8 sm:grid-cols-1 gird-col-input"
                    key={inputs.title}
                  >
                    {inputs.inputs.map((input) => {
                      switch (input.type) {
                        case 'select':
                          return (
                            <div
                              className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full"
                              key={input.name}
                            >
                              <Text
                                className="text-[15px] text-gray-900 w-auto capitalize"
                                size="txtPoppinsMedium15"
                              >
                                {input.label}
                                {input.required && (
                                  <span className="text-red-500 text-[15px] font-medium font-['Poppins'] leading-tight">
                                    *
                                  </span>
                                )}
                              </Text>
                              <SelectBox
                                className="bg-white-A700 border border-indigo-400 border-solid  px-2.5 py-3.5 rounded-[9px] text-[15px] text-black-900 text-left tracking-[0.50px] w-full"
                                placeholderClassName="text-black-900_60"
                                indicator={
                                  <div className="flex justify-center items-center w-[22px] h-[22px] bg-zinc-800 bg-opacity-10 rounded-full">
                                    <img
                                      className="h-5 w-5 rounded-[50%]"
                                      src="/images/img_uangledown.svg"
                                      alt="u:angle-down"
                                    />
                                  </div>
                                }
                                name={input.name}
                                options={input.option}
                                isSearchable={true}
                                placeholder={input.label}
                                control={form.control}
                                component="select"
                              />
                            </div>
                          )
                        case 'text':
                        case 'email':
                        case 'number':
                          return (
                            <div
                              className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full"
                              key={input.name}
                            >
                              <Text
                                className="text-[15px] text-gray-900 w-auto capitalize"
                                size="txtPoppinsMedium15"
                              >
                                {input.label}
                                {input.required && (
                                  <span className="text-red-500 text-[15px] font-medium font-['Poppins'] leading-tight">
                                    *
                                  </span>
                                )}
                              </Text>
                              <Input
                                name={input.name}
                                placeholder={input.label}
                                className="md:h-auto p-0 placeholder:text-black-900_60 sm:h-auto sm:pr-5 text-[15px] text-black-900_60 text-left tracking-[0.50px] w-full disabled:cursor-not-allowed placeholder:capitalize"
                                wrapClassName="bg-white-A700 border border-indigo-400 border-solid pl-2.5 pr-[35px] py-3.5 rounded-[9px] w-full"
                                control={form.control}
                                type={input.type}
                              />
                            </div>
                          )
                        case 'date':
                          return (
                            <div className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full">
                              <Text
                                className="text-[15px] text-gray-900 w-auto"
                                size="txtPoppinsMedium16Black900"
                              >
                                {input.label}
                              </Text>
                              <Datepicker
                                className="px-[15px] py-[10px] border  border-solid rounded-[10px]  border-indigo-400 w-full"
                                placeholder="DD/MM/YYYY"
                                control={form.control}
                                name={input.name}
                                minDate={
                                  input.label !== 'Date of Birth'
                                    ? issueDate && new Date(issueDate)
                                    : null
                                }
                              ></Datepicker>
                            </div>
                          )
                        case 'searchSelect':
                          return (
                            <div className="w-full">
                              <Text className="text-[15px] text-gray-900 w-auto capitalize">
                                {input.label}
                                {input.required && (
                                  <span className="text-red-500 text-[15px] font-medium">
                                    *
                                  </span>
                                )}
                              </Text>
                              <div>
                                <Controller
                                  name={input.name}
                                  control={form.control}
                                  render={({ field: { onChange, value } }) => (
                                    <CreatableSelect
                                      isClearable
                                      options={tradeOptions || []}
                                      value={
                                        tradeOptions.find(
                                          (option) => option.value === value,
                                        ) || null
                                      }
                                      onChange={(selectedOption) =>
                                        onChange(selectedOption?.value || '')
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter')
                                          e.preventDefault()
                                      }}
                                      placeholder={input.placeholder}
                                      formatCreateLabel={(inputValue) =>
                                        `Add "${inputValue}"`
                                      }
                                      className="mt-2 w-full rounded-[9px]"
                                      styles={{
                                        control: (base) => ({
                                          ...base,
                                          height: '53px',
                                          borderColor: '#4A7FDF',
                                          paddingLeft: '10px',
                                          borderRadius: '9px',
                                        }),
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          )
                        case 'multiSelect':
                          return (
                            <div
                              className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full"
                              key={input.name}
                            >
                              <Text
                                className="text-[15px] text-gray-900 w-auto capitalize"
                                size="txtPoppinsMedium15"
                              >
                                {input.label}
                                {input.required && (
                                  <span className="text-red-500 text-[15px] font-medium font-['Poppins'] leading-tight">
                                    *
                                  </span>
                                )}
                              </Text>
                              <SelectBox
                                className="bg-white-A700 border border-indigo-400 border-solid  px-2.5 py-3.5 rounded-[9px] text-[15px] text-black-900 text-left tracking-[0.50px] w-full"
                                placeholderClassName="text-black-900_60"
                                indicator={
                                  <div className="flex justify-center items-center w-[22px] h-[22px] bg-zinc-800 bg-opacity-10 rounded-full">
                                    <img
                                      className="h-5 w-5 rounded-[50%]"
                                      src="/images/img_uangledown.svg"
                                      alt="u:angle-down"
                                    />
                                  </div>
                                }
                                name={input.name}
                                options={input.option}
                                isSearchable={true}
                                placeholder={input.label}
                                control={form.control}
                                component="select"
                                isMulti={true}
                              />
                            </div>
                          )
                      }
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* passport scan */}
          <div className=" border border-[#191C1D] border-dashed max-h-[400px] w-full grid place-items-center min-w-[500px]  rounded-md relative cursor-pointer mt-[60px] overflow-hidden">
            <div
              className={cn(
                ' flex flex-col justify-center items-center',
                (passportPreview || pdfFileName) &&
                  'w-full h-full relative group ',
              )}
            >
              {passportPreview ? (
                <img
                  src={passportPreview}
                  alt="scanner"
                  className="w-full h-full max-w-full object-cover object-center my-0 max-h-[400px]"
                />
              ) : pdfFileName ? (
                <div className="p-3 rounded-md text-center h-full flex flex-col justify-center items-center">
                  <span className="font-semibold">{pdfFileName}</span>
                  <span className="font-medium mt-4">Document Uploaded</span>
                </div>
              ) : (
                <img
                  src="/images/scanner.png"
                  alt="scanner"
                  className="w-[49.37px] h-[49.37px] max-w-[100px] my-3"
                />
              )}

              {!passportPreview && !pdfFileName && (
                <span className="font-semibold text-[#191C1D]">
                  Upload passport document
                </span>
              )}
              {!passportPreview && !pdfFileName && (
                <span className="font-medium text-[16px] text-[#4A7FDF] mt-5 underline">
                  Upload
                </span>
              )}

              {(passportPreview || pdfFileName) && (
                <button
                  type="button"
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-sm font-medium rounded-md opacity-0 group-hover:opacity-100 transition-opacity bg-white-A700"
                  onClick={() =>
                    document.getElementById('passport-upload')?.click()
                  }
                >
                  <h1 className="py-2 px-3 w-fit bg-white-A700 rounded-md">
                    Re-upload
                  </h1>
                </button>
              )}

              <input
                id="passport-upload"
                type="file"
                accept="image/*,application/pdf"
                className="absolute top-0 right-0 w-full h-full opacity-0 cursor-pointer"
                {...form.register('passportfile' as never)}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  )
}

export default CreateCandidate
