import ModalBase from 'modals/BaseModal'
import { Input, Text } from 'components'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import useCandidate from 'hooks/useCandidate'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { cn } from 'utils'
const VerifyCandidate = ({ children }) => {
  const form = useForm()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [isValidate, setIsValidate] = useState('')
  const [redirectURL, setRedirectURL] = useState(null)
  const { verifyCandidate } = useCandidate()
  const verify = useMutation({
    mutationKey: ['verify'],
    mutationFn: verifyCandidate,
    onSuccess: (data) => {
      setIsValidate('match')
      setRedirectURL(data)
    },
    onError: (error: any) => {
      setIsValidate('notMatch')
      toast.error(error?.response?.data?.message)
    },
  })

  const onSubmit = (data) => {
    const { pan_number, passport_number } = data

    if (pan_number && passport_number) {
      toast.error(
        'Please enter either PAN Card Number or Passport Number to verify.',
      )
      return
    }

    const verifyObject = {
      verificationNumber: pan_number.trim() || passport_number.trim(),
    }

    toast.promise(verify.mutateAsync(verifyObject), {
      loading: 'Verifying Candidate...',
      success: 'Candidate already present in the system.',
      error: 'Candidate not present. You can create one.',
    })
  }

  const goProfile = () => {
    setIsOpen(!isOpen)
    navigate(`/masterview/${redirectURL?.id}`)
  }

  return (
    <ModalBase
      triggerComponent={children}
      modalClass="max-w-[900px]"
      acceptFunctions={form.handleSubmit(onSubmit)}
      defaultOpen={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      closeModalAfterAccept={false}
    >
      <div>
        <Text
          className="flex items-center w-auto gap-3 text-xl text-gray-900 capitalize mb-5"
          size="txtPoppinsMedium20Gray900"
        >
          Candidate Verification
        </Text>
        <Text>
          Enter PAN Card Number or Passport Number to check candidate exists or
          not.
        </Text>
      </div>
      <div className=" w-full flex justify-between">
        <div className=" w-[484px]">
          <div className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full mb-5">
            <Text
              className="text-[15px] text-gray-900 w-auto capitalize"
              size="txtPoppinsMedium15"
            >
              <span className=" uppercase">PAN</span> Card Number
            </Text>
            <Input
              placeholder="PAN Card Number"
              className="md:h-auto p-0 placeholder:text-black-900_60 sm:h-auto sm:pr-5 text-[15px] text-black-900_60 text-left tracking-[0.50px] w-full disabled:cursor-not-allowed"
              wrapClassName="bg-white-A700 border border-indigo-400 border-solid pl-5 pr-[35px] py-2.5 rounded-[22px] w-full"
              name="pan_number"
              control={form.control}
            />
          </div>
          <div className="w-full flex gap-2.5 items-center text-[16px] text-[#191C1D80]">
            <div className="bg-[#191C1D80] h-[2px] w-full"></div>
            or
            <div className="bg-[#191C1D80] h-[2px] w-full"></div>
          </div>
          <div className="flex sm:flex-1 flex-col gap-2.5 items-start justify-start w-full sm:w-full mt-5">
            <Text
              className="text-[15px] text-gray-900 w-auto capitalize"
              size="txtPoppinsMedium15"
            >
              Passport Number
            </Text>
            <Input
              placeholder="Passport Number"
              className="md:h-auto p-0 placeholder:text-black-900_60 sm:h-auto sm:pr-5 text-[15px] text-black-900_60 text-left tracking-[0.50px] w-full disabled:cursor-not-allowed"
              wrapClassName="bg-white-A700 border border-indigo-400 border-solid pl-5 pr-[35px] py-2.5 rounded-[22px] w-full"
              name="passport_number"
              control={form.control}
            />
          </div>
        </div>
        <div
          className={cn(
            ' w-[226.01px] py-5 px-6 rounded-[12px] bg-[#F7FAFF]  flex flex-col',
            isValidate === 'match' && 'bg-[#F0FFF0]',
            isValidate === 'notMatch' && 'bg-[#FFF5E9]',
          )}
        >
          <div className=" flex gap-2 justify-start items-center mb-3">
            <img
              src={
                isValidate === 'match'
                  ? '/images/verification-yes.svg'
                  : isValidate === 'notMatch'
                    ? '/images/verification-not.svg'
                    : '/images/verification.svg'
              }
              alt=""
            />
            {isValidate === 'notMatch' ? (
              <h1 className="text-[#F19E38] font-medium text-[14px] leading-[120%] max-w-[89px]">
                No results found
              </h1>
            ) : isValidate === 'match' ? (
              <h1 className="text-[#23AB28] font-medium text-[14px] leading-[120%] max-w-[89px]">
                Success
              </h1>
            ) : (
              <h1 className="text-[#F19E38] font-medium text-[14px] leading-[120%] max-w-[89px]"></h1>
            )}
          </div>
          <h1 className=" text-[16px] leading-[100%] font-semibold text-[#2C2C2C] mb-2 max-w-[145px]">
            {isValidate === 'notMatch'
              ? "Candidate doesn't exists"
              : isValidate === 'match'
                ? 'Candidate exists'
                : ''}
          </h1>
          <p className=" text-[14px] leading-[18px] text-[#5E5E5E] tracking-[-2%]">
            {isValidate === 'notMatch'
              ? 'You can create candidate account from your portal'
              : isValidate === 'match'
                ? 'You can access his details from master profile'
                : 'nothing to see here'}
          </p>
          {isValidate === 'notMatch' && (
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="mt-5 text-[#FFFFFF] bg-[#4A7FDF] rounded-[9px] py-[9px] px-[25px] w-fit text-[15px] leading-[20.5px]"
            >
              Create now
            </button>
          )}
          {isValidate === 'match' && (
            <button
              onClick={goProfile}
              className="mt-5 text-[#FFFFFF] bg-[#4A7FDF] rounded-[9px] py-[9px] px-[25px] w-fit text-[15px] leading-[20.5px]"
            >
              View Profile
            </button>
          )}
        </div>
      </div>
    </ModalBase>
  )
}

export default VerifyCandidate
