import { baseURL, apis } from 'service'
import useclient from './useClient'
import { StageInfo } from 'types/global'
import { applicantList } from './usePostRecurtment'

const useSelectionPropcess = () => {
  const { getAuthHeader } = useclient()

  const getPipeLineStats = async (
    requirementId: string,
  ): Promise<{
    stages: StageInfo[]
    job_id: string
    pipeline_name: string
    postrecrutmentCount: number
  }> => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/candidate/getPipelineWithCandidateCount/${requirementId}`,
        { headers: getAuthHeader() },
      )
      if (!data?.data) {
        throw new Error('no stats available the requirement.')
      }
      return data.data
    } catch (error) {
      console.error('error in getting the pipeline stats', error)
      throw error
    }
  }

  const getCadidanteByStageAndRequirement = async (params: {
    stageNo: number
    jobId: string
  }): Promise<applicantList[]> => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/candidate/getcandidatesbystage`,
        {
          data: params,
          headers: getAuthHeader(),
        },
      )
      if (!data?.data) {
        throw new Error('no candidate available for this requirement.')
      }
      return data.data
    } catch (error) {
      console.error('error in getting the candidate by stageName', error)
    }
  }

  const evaluateApplicant = async (points: object) => {
    try {
      await apis.post(
        `${baseURL}/recruiter/api/v1/candidate/evaluatecandidate`,
        {
          data: points,
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error('error in evaluateApplicant', error)
      throw error
    }
  }

  const moveCandidateToNextStage = async (params: object) => {
    try {
      await apis.post(`${baseURL}/recruiter/api/v1/candidate/movetonextstage`, {
        data: params,
        headers: getAuthHeader(),
      })
      return true
    } catch (error) {
      console.error('error in moveCandidateToNextStage', error)
      throw error
    }
  }

  //NOTE:this endpoint can be used for the rejection of the candidate as well as the to give the remark
  const handleRejectionAndRemark = async (params: object) => {
    try {
      await apis.post(`${baseURL}/recruiter/api/v1/candidate/addremark`, {
        data: params,
        headers: getAuthHeader(),
      })
      return true
    } catch (error) {
      console.error('error in handleRejectionAndRemark ', error)
    }
  }

  return {
    getPipeLineStats,
    getCadidanteByStageAndRequirement,
    handleRejectionAndRemark,
    moveCandidateToNextStage,
    evaluateApplicant,
  }
}

export default useSelectionPropcess
