import { default as ModalProvider } from 'react-modal'
import { useForm } from 'react-hook-form'
import { AiOutlineUpload } from 'react-icons/ai'
import { Button, Text } from 'components'
import { RxCrossCircled } from 'react-icons/rx'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useCandidate from 'hooks/useCandidate'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { ChangeEvent, useState } from 'react'
interface FormValues {
  csvFile: FileList | null | any
}

const NewBulkUpload = ({ children }: { children: React.ReactNode }) => {
  const { UploadCandidates } = useCandidate()
  const [open, setOpen] = useState(false)
  const handleModal = () => {
    setOpen(!open)
    if (step === 2) setStep(1)
  }
  const [step, setStep] = useState(1)

  //TODO:change this any type
  const form = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        csvFile: Yup.mixed()
          .required('Required')
          .test('fileFormat', 'Unsupported Format', (value) => {
            if (!value || !Array.isArray(value) || value.length === 0) {
              return false
            }
            return ['text/csv', 'application/vnd.ms-excel'].includes(
              value[0].type,
            )
          }),
      }),
    ),
    defaultValues: {
      csvFile: [],
    },
  })

  const {
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = form

  const csvFile = watch('csvFile')
  const { data, ...UploadHandler } = useMutation({
    mutationFn: UploadCandidates,
    onSuccess: () => {
      toast.success('Upload Successful', {
        id: 'upload-candidate',
      })
      if (step === 2) setStep(1)
      setStep((prev) => prev + 1)
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message, {
        id: 'upload-candidate',
      })
    },
    onMutate: () => {
      toast.loading('Uploading', {
        id: 'upload-candidate',
      })
    },
  })

  const onSubmit = async (data: FormValues) => {
    UploadHandler.mutate(data)
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const filesArray = Array.from(files)

      const isValidFormat = ['text/csv', 'application/vnd.ms-excel'].includes(
        filesArray[0].type,
      )

      if (!isValidFormat) {
        toast.error(
          'Unsupported file format. Please upload a CSV or Excel file.',
          {
            id: 'file-format-error',
          },
        )

        e.target.value = ''

        setError('csvFile', {
          type: 'manual',
          message: 'Unsupported file format',
        })

        return
      }

      form.setValue('csvFile', filesArray)
      clearErrors('csvFile')
    }
  }

  return (
    <>
      {children && (
        <button onClick={handleModal} type="button">
          {children}
        </button>
      )}
      <ModalProvider
        appElement={document.getElementById('root')}
        className="m-auto !w-[42%]"
        overlayClassName="bg-black-900_99 fixed flex h-full inset-y-[0] w-full"
        isOpen={open}
        onRequestClose={handleModal}
      >
        <div className="bg-white-A700 flex flex-col gap-3 items-start justify-start px-10 pt-5 pb-10 md:px-5 rounded-[15px] md:w-full w-full">
          <p className=" w-full text-right">Step {step}/2</p>
          {step === 2 ? (
            <Step2 step={step} onClose={handleModal} onClick={() => {}} />
          ) : (
            <div className="w-full space-y-3">
              <div className="flex items-center justify-between w-full">
                <Text
                  className="flex items-center w-auto gap-3 text-xl text-gray-900 capitalize"
                  size="txtPoppinsMedium20Gray900"
                >
                  Bulk Upload
                </Text>
                {!UploadHandler.isSuccess && (
                  <Button
                    className="bg-blue-50_02 p-[7px_11px] w-full max-w-[95px] text-blue-A200 rounded-lg relative"
                    type="button"
                  >
                    Reupload
                    <input
                      type="file"
                      className="absolute top-0 right-0 w-full h-full opacity-0"
                      onChange={handleFileChange}
                      placeholder="candidate file"
                    />
                  </Button>
                )}
              </div>
              <div className="border border-indigo-400 border-solid max-h-[400px] w-full grid place-items-center min-w-[500px] rounded-md relative cursor-pointer p-5">
                {csvFile && csvFile.length > 0 ? (
                  <div className="flex items-center justify-between w-full bg-gray-200_01 p-[7px_10px] rounded-lg">
                    {csvFile[0].name}
                    <RxCrossCircled
                      className="text-xl"
                      onClick={() => form.reset({ csvFile: [] })}
                    />
                  </div>
                ) : (
                  <div className="grid p-10 cursor-pointer place-items-center">
                    <AiOutlineUpload className="my-3 text-3xl text-blue-A200" />
                    <span className="underline text-blue-A200">Upload</span>
                    <input
                      type="file"
                      className="absolute top-0 right-0 w-full h-full opacity-0"
                      onChange={handleFileChange}
                      placeholder="Passport Image"
                    />
                  </div>
                )}
              </div>
              {errors.csvFile && (
                <span className="text-red-500 text-sm">
                  {errors.csvFile.message}
                </span>
              )}
              <div className="py-2.5 justify-end w-full items-center gap-5 inline-flex">
                <Button
                  className="w-auto text-base text-center text-gray-700"
                  onClick={() => {
                    if (form.getValues('csvFile').length > 0) {
                      form.reset({ csvFile: [] })
                    }
                    setStep((prev) => prev + 1)
                  }}
                  type="button"
                >
                  Skip to next
                </Button>
                <Button
                  className="bg-blue-A200 border-[3px] border-blue-A200 border-solid cursor-pointer h-[35px] min-w-[103px] px-2.5 rounded-[5px] text-center text-sm text-white-A700 disabled:cursor-not-allowed"
                  type="button"
                  onClick={form.handleSubmit(onSubmit)}
                  disabled={!csvFile?.length || UploadHandler.isLoading}
                >
                  Upload Candidate
                </Button>
              </div>
            </div>
          )}
        </div>
      </ModalProvider>
    </>
  )
}

const Step2 = ({
  onClick,
  onClose,
  step,
}: {
  step: number
  onClose: () => void
  onClick: () => void
}) => {
  const { uploadMultipleCandidateDoc } = useCandidate()
  const form = useForm<FormValues>({
    defaultValues: {
      csvFile: [],
    },
  })

  const [successResult, setSuccessResult] = useState<any>({})

  const csvFiles = form.watch('csvFile')
  const { data, ...UploadDocHandler } = useMutation({
    mutationFn: uploadMultipleCandidateDoc,
    onSuccess: (data) => {
      toast.success('Bulk Upload Successful', {
        id: 'bullk-upload-candidate',
      })
      setSuccessResult(data?.data)
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message, {
        id: 'bullk-upload-candidate',
      })
    },
    onMutate: () => {
      toast.loading('Uploading', {
        id: 'bullk-upload-candidate',
      })
    },
  })

  const onSubmit = async (data: FormValues) => {
    UploadDocHandler.mutate(data)
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const filesArray = Array.from(files)

      if (csvFiles.length + filesArray.length > 10000) {
        toast.error('Maximum of 10,000 files allowed.', {
          id: 'file-count-error',
        })
        return
      }

      for (const file of filesArray) {
        if (file.size > 25 * 1024 * 1024) {
          toast.error(`File "${file.name}" exceeds the 25MB size limit.`, {
            id: 'file-size-error',
          })
          return
        }

        const supportedTypes = [
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'text/plain',
          'text/csv',
          'image/jpeg',
          'image/png',
          'image/gif',
          'image/webp',
          'image/svg+xml',
          'application/zip',
          'application/x-rar-compressed',
          'application/x-7z-compressed',
        ]
        if (!supportedTypes.includes(file.type)) {
          toast.error(`File "${file.name}" has an unsupported file type.`, {
            id: 'file-type-error',
          })
          return
        }

        const fileNamePattern = /^[A-Z0-9]{6,9}-[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+$/
        if (!fileNamePattern.test(file.name)) {
          toast.error(
            `File "${file.name}" does not follow the naming convention {passport_number}-{document_name}.`,
            {
              id: 'file-name-error',
            },
          )
          return
        }
      }

      form.setValue('csvFile', [...csvFiles, ...filesArray])
    }
  }

  const generateCsvFile = (errors: string[]) => {
    let csvContent = 'data:text/csv;charset=utf-8,Filename,Reason\r\n'
    errors.forEach((row) => {
      const [reason, fileName] = row.split('#')
      csvContent += `${fileName},${reason}\r\n`
    })
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'failed_reasons.csv')
    document.body.appendChild(link)
    link.click()
  }

  return (
    <div className="w-full space-y-3">
      <div className="flex justify-between w-full">
        <Text
          className="flex items-center w-auto gap-3 text-xl text-gray-900 capitalize"
          size="txtPoppinsMedium20Gray900"
        >
          Bulk Upload
        </Text>
        {csvFiles?.length > 0 && !UploadDocHandler?.isSuccess && (
          <Button
            className="bg-blue-50_02 p-[7px_11px] text-blue-A200 rounded-lg relative"
            type="button"
          >
            Upload more
            <input
              type="file"
              multiple
              className="absolute top-0 right-0 w-full h-full opacity-0"
              onChange={handleFileChange}
              placeholder="Passport Image"
            />
          </Button>
        )}
      </div>
      {UploadDocHandler?.isSuccess ? (
        <div className="w-full text-[#464646] font-poppins text-base">
          <Text className="mb-5 font-medium ">
            Congratulations , You have uploaded &nbsp;
            <span className="text-center border-2  border-none bg-[#E9FFEA] text-[#3FB73D] p-1 px-3 rounded-md text-sm font-medium font-roboto ">
              {successResult?.totalUploaded || 0} CVs
            </span>
            &nbsp; successfully
          </Text>
          <Text className="text-[#B6B6B6] mb-5">
            <span className="text-center border-2  border-none bg-[#FFE2E2] text-[#F13838] p-1 px-3 rounded-md text-sm font-medium font-roboto">
              {successResult?.totalErrors || 0} Failed uploading{' '}
            </span>{' '}
            &nbsp; Please check the upload requirements
          </Text>
          <Text
            className="underline flex gap-1 items-center cursor-pointer text-[15px] leading-[20.5px] font-medium "
            onClick={() => generateCsvFile(successResult?.errors)}
          >
            {' '}
            <img src="/images/sheet.svg" alt="spreadsheet" /> Failed reasons
            sheet
          </Text>
        </div>
      ) : (
        <div className="border border-indigo-400 border-solid max-h-[400px] w-full grid place-items-center min-w-[500px] rounded-md relative cursor-pointer p-5">
          {csvFiles && csvFiles.length > 0 ? (
            <div className="w-full space-y-2">
              {csvFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between w-full bg-gray-200_01 p-[7px_10px] rounded-lg"
                >
                  {file.name}
                  <RxCrossCircled
                    className="text-xl cursor-pointer"
                    onClick={() => {
                      const updatedFiles = csvFiles.filter(
                        (_, i) => i !== index,
                      )
                      form.setValue('csvFile', updatedFiles)
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="grid p-10 cursor-pointer place-items-center">
              <AiOutlineUpload className="my-3 text-3xl text-blue-A200" />
              <span className="underline text-blue-A200">Upload</span>
              <input
                type="file"
                multiple
                className="absolute top-0 right-0 w-full h-full opacity-0"
                onChange={handleFileChange}
                placeholder="Passport Image"
              />
            </div>
          )}
        </div>
      )}
      {!UploadDocHandler?.isSuccess ? (
        <div className="py-2.5 justify-end w-full items-center gap-5 inline-flex">
          <Button
            className="w-auto text-base text-center text-gray-700"
            onClick={onClose}
            type="button"
          >
            Cancel
          </Button>
          <Button
            className="bg-blue-A200 border-[3px] border-blue-A200 border-solid cursor-pointer h-[35px] min-w-[103px] px-2.5 rounded-[5px] text-center text-sm text-white-A700 disabled:cursor-not-allowed"
            type="button"
            onClick={form.handleSubmit(onSubmit)}
            disabled={
              UploadDocHandler.isLoading ||
              !csvFiles?.length ||
              UploadDocHandler.isSuccess
            }
          >
            Upload Candidate
          </Button>
        </div>
      ) : (
        <div className="py-2.5 justify-staer w-full items-center gap-5 inline-flex">
          <Button
            className="bg-blue-A200 border-[3px] border-blue-A200 border-solid cursor-pointer h-[35px] min-w-[103px] px-2.5 rounded-[5px] text-center text-sm text-white-A700 disabled:cursor-not-allowed"
            onClick={onClose}
            type="button"
          >
            Close
          </Button>
        </div>
      )}
    </div>
  )
}

export default NewBulkUpload
