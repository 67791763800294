import React from 'react'

import { Img, Text } from 'components'

type PlusButtonProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'projectname' | 'onClick'
> &
  Partial<{
    projectname: string
    onClick: React.MouseEventHandler<HTMLElement>
  }>

const PlusButton: React.FC<PlusButtonProps> = (props) => {
  return (
    <>
      <div className={props.className} onClick={props?.onClick}>
        {props.projectname ||
          (props.title && (
            <Text className="text-[15px] w-auto font-medium font-poppins capitalize">
              {props?.projectname || props?.title}
            </Text>
          ))}
        <Img
          className="w-6 h-6 bg-white-A700 ml-2 rounded-md"
          src="/images/img_plus_blue_a200.svg"
          alt="plus"
        />
      </div>
    </>
  )
}

export default PlusButton
