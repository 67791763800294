import React from 'react'
import { Text } from '../../components'
type StatsBoxProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'title' | 'value'
> &
  Partial<{ title: string; value: string | Record<string, string> }>

const StatsBox: React.FC<StatsBoxProps> = React.memo(
  ({ title, value, className }) => {
    return (
      <div className={`flex items-center gap-3 ${className}`}>
        <div className="text-sm font-medium text-gray-900 font-poppins">
          {title}
        </div>

        {typeof value === 'string' ? (
          <div className="text-blue-600 font-semibold text-lg">{value}</div>
        ) : (
          <div className="flex items-center gap-4">
            {Object.entries(value).map(([key, val]) => {
              const colorClass =
                key.toLowerCase() === 'rejected'
                  ? 'bg-red-100 text-red-600'
                  : key.toLowerCase() === 'reached'
                    ? 'bg-yellow-100 text-yellow-600'
                    : 'text-blue-600'

              return (
                <div
                  key={key}
                  className={`${
                    key.toLowerCase() === 'total cv source'
                      ? 'text-blue-600 font-semibold text-lg'
                      : 'px-3 py-1 rounded-full font-semibold'
                  } ${colorClass}`}
                >
                  {key !== 'Total CV Source' && (
                    <span className="mr-3 text-black-900 font-medium text-xs capitalize">
                      {key}
                    </span>
                  )}
                  {val}
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  },
)

StatsBox.defaultProps = { title: 'Stat Title', value: 'NA' }

export default StatsBox
