import React from 'react'
import ModalBase from 'modals/BaseModal'
import { Text } from 'components'
import { MdOutlineContentCopy } from 'react-icons/md'
import { toast } from 'react-hot-toast' // Assuming you have react-hot-toast installed

const CopyContact = ({ children, number }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(number)
      .then(() => {
        toast.success('Phone number copied to clipboard!')
      })
      .catch((err) => {
        toast.error('Failed to copy phone number.')
        console.error('Could not copy text: ', err)
      })
  }

  return (
    <ModalBase
      triggerComponent={children}
      modalClass="max-w-[400px]"
      saveTitle="Done"
    >
      <div className="w-full">
        <Text
          className="w-auto text-xl text-gray-900 capitalize"
          size="txtPoppinsMedium20Gray900"
        >
          Phone Contact
        </Text>
        <div
          className="flex items-center justify-between p-3 mt-5 border border-gray-300 border-solid rounded-md cursor-pointer"
          onClick={handleCopy}
        >
          {number}
          <MdOutlineContentCopy />
        </div>
        <Text className="w-auto text-sm text-[#F19E38] capitalize mt-1 font-poppins">
          copy the number
        </Text>
      </div>
    </ModalBase>
  )
}

export default CopyContact
