import { Text } from 'components'
import React from 'react'
import { cn } from 'utils'

export type ProfileCardProps = {
  profile: {
    label: string
    desc?: string
    id: string
    candidateId?: string
  }
  activeClass?: string
  loading?: boolean
  clickFunction?: (profile: object) => void
  applicantDetails?: any
}

const getTextColor = (status: string) => {
  switch (status) {
    case 'Ready For Departure':
      return 'text-[#791CF0]'
    case 'In Progress':
      return 'text-yellow-500'
    case 'Rejected':
      return 'text-red-600'
    default:
      return 'text-black'
  }
}

const ProfileCard = React.memo((props: ProfileCardProps) => {
  const { profile, activeClass, loading, clickFunction, applicantDetails } =
    props

  const profileFunction = () => {
    if (typeof clickFunction === 'function') {
      clickFunction(profile)
    }
  }

  // console.log("profile ", profile);
  // console.log("applicantDetails ", applicantDetails);
  const applicantStages =
    applicantDetails?.candidate?.id === profile?.candidateId
      ? applicantDetails?.flyout_details
      : []

  const isReadyForDeparture =
    applicantStages &&
    Object.values(applicantStages).every((status) => status === true)
  const state = isReadyForDeparture ? 'Ready for Departure' : 'In Progress'

  // console.log("applicantStages ", applicantStages);

  return (
    <>
      {loading ? (
        <div>loading...</div>
      ) : (
        <button
          className={cn(
            'border-solid flex gap-2.5 items-center my-0 p-2.5 w-full cursor-pointer',
            activeClass,
          )}
          onClick={profileFunction}
          type="button"
        >
          <Text
            className="bg-white-A700 border border-blue-A200 border-solid flex h-[43px] items-center justify-center rounded-[21px] text-[15.84px] text-black-900 text-center w-[43px] capitalize"
            size="txtMontserratRomanRegular1584"
          >
            {profile?.label?.slice(0, 1)}
          </Text>
          <div className="flex flex-col items-start justify-center w-auto gap-px capitalize font-poppins">
            <Text
              className="w-auto text-base text-blue-A200"
              size="txtPoppinsMedium16BlueA200"
            >
              {profile.label}
            </Text>
            <Text
              className={cn(
                'w-auto text-xs capitalize text-black-900_01',
                getTextColor(
                  profile?.desc === 'direct' ? state : profile?.desc,
                ),
              )}
              size="txtPoppinsRegular12Black90001"
            >
              {profile?.desc === 'direct' ? state : profile?.desc}
            </Text>
          </div>
        </button>
      )}
    </>
  )
})

export default ProfileCard
