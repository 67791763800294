import { useMemo } from 'react'
import { Img, Text, SelectBox } from 'components'
import { useFormContext } from 'react-hook-form'
import NotFound from 'components/NotFound'
import { RxCross1 } from 'react-icons/rx'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import useRequirement from 'hooks/useRequirement'
import toast from 'react-hot-toast'
interface requireterDataProps {
  _id: string
  access: string[]
  name: string
  userType: string
  access_stages: string[]
  email: string
  profilePicUrl: string
}

const RequirementOverviewListitem = ({
  requireterData,
  stages,
}: {
  requireterData: requireterDataProps[]
  stages: string[]
}) => {
  const { control, getValues } = useFormContext()
  const { requirementId } = useParams()
  const { delteRecruiter } = useRequirement()
  const queryclient = useQueryClient()

  /**
   * Deletes a recruiter.
   *
   * @remarks
   * This function is used to delete a recruiter and invalidates the 'recruitersDetail' query cache upon success.
   *
   * @returns - The delete operation mutation.
   */
  const deleteOperation = useMutation({
    mutationKey: ['deleteRecruiter'],
    mutationFn: delteRecruiter,
    onSuccess: () => {
      queryclient.invalidateQueries('recruitersDetail')
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
  })

  const deleteHandler = (recruiterId: string) => {
    toast.promise(
      deleteOperation.mutateAsync({
        recruiter_id: recruiterId,
        job_id: requirementId,
      }),
      {
        loading: 'Deleting...',
        success: 'Deleted Successfully',
        error: 'Failed to delete',
      },
    )
  }
  //creating options based on the stages
  let stagesOption = useMemo(() => {
    return stages?.map((stage) => ({
      label: stage,
      value: stage,
    }))
  }, [stages])

  //restricting the requirement to have the colide access with the other requireter access
  // const restrictAccess = (recruiterId: string) => {
  //   let access = getValues()

  //   Object.values(access).map((value) => {})
  // }

  if (!requireterData || requireterData.length == 0) {
    return (
      <NotFound title="No access available" subtitle="add access to edit it." />
    )
  }

  return (
    <>
      {requireterData.map((recruiter: requireterDataProps) => (
        <div
          className="flex flex-1 md:flex-col flex-row gap-[50px] items-center justify-start my-0 w-full cursor-pointer "
          key={recruiter._id}
        >
          <div className="flex flex-row gap-[25px] items-center justify-start w-[50%] group">
            <div className="relative">
              <Img
                className="h-[60px] md:h-auto rounded-[50%] w-[60px]"
                src={
                  recruiter?.profilePicUrl || '/images/img_profilepicture.png'
                }
                alt="profilepicture"
              />
              <RxCross1
                className="absolute text-[60px] text-red-500 top-0 border rounded-full border-red-500 p-2 invisible group-hover:visible transition"
                onClick={() => deleteHandler(recruiter._id)}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-auto gap-3 ">
              <Text
                className="capitalize text-[15px] text-black-900 w-full"
                size="txtPoppinsMedium15Black900"
              >
                {recruiter.name || recruiter.email}
              </Text>
              <Text
                className="capitalize text-[12px] text-blue_gray-900_01 w-full"
                size="txtPoppinsRegular15Bluegray90001"
              >
                {recruiter.userType}
              </Text>
            </div>
          </div>
          <SelectBox
            className="bg-white-A700 border border-[#2B2B2B1A] border-solid h-auto pl-2.5 pr-[13px] py-3.5 rounded-[9px] text-[15px] text-black-900 text-left tracking-[0.50px] w-[60%]"
            placeholderClassName="text-black-900_60"
            indicator={
              <div className="flex justify-center items-center w-[22px] h-[22px] bg-zinc-800 bg-opacity-10 rounded-full">
                <Img
                  className="h-5 w-5 rounded-[50%]"
                  src="/images/img_uangledown.svg"
                  alt="u:angle-down"
                />
              </div>
            }
            isMulti={true}
            name={recruiter._id}
            options={stagesOption}
            isSearchable={true}
            component="select"
            placeholder="Select Process to Give Edit access"
            control={control}
            defaultValue={recruiter.access_stages}
          />
        </div>
      ))}
    </>
  )
}

export default RequirementOverviewListitem
