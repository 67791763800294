import { apis, baseURL } from 'service'
import useclient from './useClient'
import { QueryData } from './useFilter'
import { getBase64 } from 'helper'
interface Stage {
  stageNo: number
  stageName: string
  candidateCount: number
}

export interface Job {
  job_id: string
  JobId: string
  job_title: string
  pipeline_name: string
  pipeline_type: string
  stages: Stage[]
  status: string
  projectId: string | null
  client_name: string | null
  location: string | null
}

export type JobsData = Job[]

function useRequirement() {
  const { getAuthHeader } = useclient()

  const getRequirement = async (requirementId) => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/job/${requirementId}`,
        {
          headers: getAuthHeader(),
        },
      )
      if (!data.data) {
        throw new Error('Could not any requirement')
      }
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const deleteRequirement = async (requirementId) => {
    try {
      const { data } = await apis.delete(
        `${baseURL}/recruiter/api/v1/job/delete/${requirementId}`,
        {
          headers: getAuthHeader(),
        },
      )
      return data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getAllRequirement = async (params: QueryData, projectId: string) => {
    const { options, query } = params
    try {
      const { data } = await apis.post(`${baseURL}/recruiter/api/v1/job/list`, {
        data: {
          ...options,
          query: {
            ...query,
            project: projectId,
          },
        },
        headers: getAuthHeader(),
      })
      data.data == null && { data: [] }
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const createRequirement = async (params: { id: string; updateData: any }) => {
    console.log('Hi')
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/job/addMultipleJobs`,
        {
          data: {
            ...params.updateData,
          },
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const updateRequirement = async (params: { id: string; updateData: any }) => {
    try {
      const { data } = await apis.put(
        `${baseURL}/recruiter/api/v1/job/update/${params.id}`,
        {
          data: {
            ...params.updateData,
          },
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getAllOrderId = async () => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/orders/details`,
        {
          headers: getAuthHeader(),
        },
      )
      if (!data.data) {
        return []
      }
      const transformedData = data.data.map((order) => ({
        label: order.branch_id || order.name,
        value: order.id,
      }))
      return transformedData
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getAllOrdersByProjectId = async (params: string) => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/orders/getOrderByProjectId/${params}`,
        {
          headers: getAuthHeader(),
        },
      )
      if (!data.data) {
        return []
      }
      const transformedData = data.data.map((order) => ({
        label: order.name,
        value: order.id,
      }))
      return transformedData
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getAllBranchId = async () => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/branches/details`,
        {
          headers: getAuthHeader(),
        },
      )
      if (!data.data) {
        return []
      }
      const transformedData = data.data.map((branch) => ({
        label: branch.branch_id || branch.name,
        value: branch.id,
      }))
      return transformedData
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getRecruiter = async (requirementId: string) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/getRecruitersByjob/${requirementId}`,
        {
          headers: getAuthHeader(),
        },
      )
      return data.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  const delteRecruiter = async ({ recruiter_id, job_id }) => {
    try {
      await apis.post(`${baseURL}/recruiter/api/v1/job/deleteRecruiter`, {
        data: { recruiter_id, job_id },
        headers: getAuthHeader(),
      })
      return true
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  const addAndGiveAccessToRecruiter = async (requiterData: object) => {
    try {
      const { data } = await apis.post(
        `${baseURL}/recruiter/api/v1/job/addRecruiterInJob`,
        {
          data: requiterData,
          headers: getAuthHeader(),
        },
      )
      return true
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getrequirementDetailList = async (): Promise<JobsData> => {
    try {
      const { data } = await apis.get(
        `${baseURL}/recruiter/api/v1/jobs/details`,
        {
          headers: getAuthHeader(),
        },
      )
      const response = data.data ? data.data : []
      return response
    } catch (error) {
      console.error('error getting requirement detail list', error)
      throw error
    }
  }
  return {
    getAllRequirement,
    createRequirement,
    updateRequirement,
    getAllOrderId,
    getAllBranchId,
    getRequirement,
    getRecruiter,
    delteRecruiter,
    addAndGiveAccessToRecruiter,
    getrequirementDetailList,
    getAllOrdersByProjectId,
    deleteRequirement,
  }
}

export default useRequirement
