export const userRoleOptions = [
  { label: 'Admin', value: 'Admin' },
  { label: 'Employee', value: 'Employee' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Recruitment Coordinator', value: 'Recruitment_Coordinator' },
  { label: 'Receptionist', value: 'Receptionist' },
]

export const TrueOrFalse = [
  { label: 'yes', value: 'true' },
  { label: 'no', value: 'false' },
]

export const YesOrNo = [
  { label: 'yes', value: 'Yes' },
  { label: 'no', value: 'No' },
]
export const religionOptions = [
  { label: 'Christianity', value: 'christianity' },
  { label: 'Islam', value: 'islam' },
  { label: 'Hinduism', value: 'hinduism' },
  { label: 'Buddhism', value: 'buddhism' },
  { label: 'Judaism', value: 'judaism' },
  { label: 'Sikhism', value: 'sikhism' },
  { label: 'Other', value: 'other' },
  // Add more religion options as needed
]

export const genderOptions = [
  { label: 'male', value: 'male' },
  {
    label: 'female',
    value: 'female',
  },
  {
    label: 'other',
    value: 'other',
  },
]

export const qualificationOptions = [
  { label: 'Class 5', value: 'Class 5' },
  { label: 'Class 6', value: 'Class 6' },
  { label: 'Class 7', value: 'Class 7' },
  { label: 'Class 8', value: 'Class 8' },
  { label: 'Class 9', value: 'Class 9' },
  { label: 'Class 10', value: 'Class 10' },
  { label: 'SSC', value: 'ssc' },
]

export const languageOptionsList = [
  { label: 'English', value: 'english' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'French', value: 'french' },
  { label: 'German', value: 'german' },
  { label: 'Arabic', value: 'arabic' },
  { label: 'Urdu', value: 'urdu' },
  { label: 'Hindi', value: 'hindi' },
  { label: 'Bengali', value: 'bengali' },
  { label: 'Telugu', value: 'telugu' },
  { label: 'Marathi', value: 'marathi' },
  { label: 'Tamil', value: 'tamil' },
  { label: 'Gujarati', value: 'gujarati' },
  { label: 'Kannada', value: 'kannada' },
  { label: 'Malayalam', value: 'malayalam' },
  { label: 'Odia', value: 'odia' },
  { label: 'Punjabi', value: 'punjabi' },
  { label: 'Assamese', value: 'assamese' },
  { label: 'Maithili', value: 'maithili' },
  { label: 'Bhili', value: 'bhili' },
  { label: 'Santali', value: 'santali' },
  { label: 'Kashmiri', value: 'kashmiri' },
  { label: 'Nepali', value: 'nepali' },
  { label: 'Konkani', value: 'konkani' },
  { label: 'Sindhi', value: 'sindhi' },
  { label: 'Dogri', value: 'dogri' },
  { label: 'Meitei', value: 'meitei' },
  { label: 'Bodo', value: 'bodo' },
  // Add more language options as needed
]

export const jobTypeOptions = [
  { label: 'Full-time', value: 'full_time' },
  { label: 'Part-time', value: 'part_time' },
  // Add more job type options as needed
]

export const evaluateOption = [
  {
    label: 'Very Bad',
    value: '1',
  },
  {
    label: 'Bad',
    value: '2',
  },
  {
    label: 'Good',
    value: '3',
  },
  {
    label: 'Very Good',
    value: '4',
  },
  {
    label: 'Excellent',
    value: '5',
  },
]

export const skillsOptions = [
  { value: 'Construction Worker', label: 'Construction Worker' },
  { value: 'Electrician', label: 'Electrician' },
  { value: 'Plumber', label: 'Plumber' },
  { value: 'Welder', label: 'Welder' },
  { value: 'Carpenter', label: 'Carpenter' },
  { value: 'Mechanics', label: 'Mechanics' },
  { value: 'Truck Driver', label: 'Truck Driver' },
  { value: 'HVAC Technician', label: 'HVAC Technician' },
  { value: 'Factory Worker', label: 'Factory Worker' },
  { value: 'Landscaper', label: 'Landscaper' },
  { value: 'Maintenance Worker', label: 'Maintenance Worker' },
  { value: 'Factory Worker', label: 'Factory Worker' },
  { value: 'Painter', label: 'Painter' },
  { value: 'Mason', label: 'Mason' },
  { value: 'Roofer', label: 'Roofer' },
  { value: 'Heavy Equipment Operator', label: 'Heavy Equipment Operator' },
]

export const currencyOptions = [
  { label: 'Bahraini Dinar (BHD)', value: 'BHD' },
  { label: 'Kuwaiti Dinar (KWD)', value: 'KWD' },
  { label: 'Omani Rial (OMR)', value: 'OMR' },
  { label: 'Qatari Riyal (QAR)', value: 'QAR' },
  { label: 'Saudi Riyal (SAR)', value: 'SAR' },
  { label: 'UAE Dirham (AED)', value: 'AED' },
  { label: 'Afghan Afghani (AFN)', value: 'AFN' },
  { label: 'Albanian Lek (ALL)', value: 'ALL' },
  { label: 'Algerian Dinar (DZD)', value: 'DZD' },
  { label: 'Andorran Euro (EUR)', value: 'EUR' },
  { label: 'Angolan Kwanza (AOA)', value: 'AOA' },
  { label: 'Argentine Peso (ARS)', value: 'ARS' },
  { label: 'Armenian Dram (AMD)', value: 'AMD' },
  { label: 'Australian Dollar (AUD)', value: 'AUD' },
  { label: 'Azerbaijani Manat (AZN)', value: 'AZN' },
  { label: 'Bahamian Dollar (BSD)', value: 'BSD' },
  { label: 'Bangladeshi Taka (BDT)', value: 'BDT' },
  { label: 'Barbadian Dollar (BBD)', value: 'BBD' },
  { label: 'Belarusian Ruble (BYN)', value: 'BYN' },
  { label: 'Belize Dollar (BZD)', value: 'BZD' },
  { label: 'Beninese CFA Franc (XOF)', value: 'XOF' },
  { label: 'Bhutanese Ngultrum (BTN)', value: 'BTN' },
  { label: 'Bolivian Boliviano (BOB)', value: 'BOB' },
  { label: 'Bosnia-Herzegovina Convertible Mark (BAM)', value: 'BAM' },
  { label: 'Botswana Pula (BWP)', value: 'BWP' },
  { label: 'Brazilian Real (BRL)', value: 'BRL' },
  { label: 'British Pound Sterling (GBP)', value: 'GBP' },
  { label: 'Brunei Dollar (BND)', value: 'BND' },
  { label: 'Bulgarian Lev (BGN)', value: 'BGN' },
  { label: 'Burundian Franc (BIF)', value: 'BIF' },
  { label: 'Cambodian Riel (KHR)', value: 'KHR' },
  { label: 'Canadian Dollar (CAD)', value: 'CAD' },
  { label: 'Cape Verdean Escudo (CVE)', value: 'CVE' },
  { label: 'Cayman Islands Dollar (KYD)', value: 'KYD' },
  { label: 'Central African CFA Franc (XAF)', value: 'XAF' },
  { label: 'Chilean Peso (CLP)', value: 'CLP' },
  { label: 'Chinese Yuan Renminbi (CNY)', value: 'CNY' },
  { label: 'Colombian Peso (COP)', value: 'COP' },
  { label: 'Comorian Franc (KMF)', value: 'KMF' },
  { label: 'Congolese Franc (CDF)', value: 'CDF' },
  { label: 'Costa Rican Colón (CRC)', value: 'CRC' },
  { label: 'Croatian Kuna (HRK)', value: 'HRK' },
  { label: 'Cuban Peso (CUP)', value: 'CUP' },
  { label: 'Czech Koruna (CZK)', value: 'CZK' },
  { label: 'Danish Krone (DKK)', value: 'DKK' },
  { label: 'Djiboutian Franc (DJF)', value: 'DJF' },
  { label: 'Dominican Peso (DOP)', value: 'DOP' },
  { label: 'East Caribbean Dollar (XCD)', value: 'XCD' },
  { label: 'Egyptian Pound (EGP)', value: 'EGP' },
  { label: 'Eritrean Nakfa (ERN)', value: 'ERN' },
  { label: 'Euro (EUR)', value: 'EUR' },
  { label: 'Fiji Dollar (FJD)', value: 'FJD' },
  { label: 'Gambian Dalasi (GMD)', value: 'GMD' },
  { label: 'Georgian Lari (GEL)', value: 'GEL' },
  { label: 'Ghanaian Cedi (GHS)', value: 'GHS' },
  { label: 'Guatemalan Quetzal (GTQ)', value: 'GTQ' },
  { label: 'Guinean Franc (GNF)', value: 'GNF' },
  { label: 'Guyanaese Dollar (GYD)', value: 'GYD' },
  { label: 'Haitian Gourde (HTG)', value: 'HTG' },
  { label: 'Honduran Lempira (HNL)', value: 'HNL' },
  { label: 'Hong Kong Dollar (HKD)', value: 'HKD' },
  { label: 'Hungarian Forint (HUF)', value: 'HUF' },
  { label: 'Icelandic Krona (ISK)', value: 'ISK' },
  { label: 'Indian Rupee (INR)', value: 'INR' },
  { label: 'Indonesian Rupiah (IDR)', value: 'IDR' },
  { label: 'Iranian Rial (IRR)', value: 'IRR' },
  { label: 'Iraqi Dinar (IQD)', value: 'IQD' },
  { label: 'Israeli New Shekel (ILS)', value: 'ILS' },
  { label: 'Jamaican Dollar (JMD)', value: 'JMD' },
  { label: 'Japanese Yen (JPY)', value: 'JPY' },
  { label: 'Jordanian Dinar (JOD)', value: 'JOD' },
  { label: 'Kazakhstani Tenge (KZT)', value: 'KZT' },
  { label: 'Kenyan Shilling (KES)', value: 'KES' },
  { label: 'Lao Kip (LAK)', value: 'LAK' },
  { label: 'Lebanese Pound (LBP)', value: 'LBP' },
  { label: 'Lesotho Loti (LSL)', value: 'LSL' },
  { label: 'Libyan Dinar (LYD)', value: 'LYD' },
  { label: 'Macanese Pataca (MOP)', value: 'MOP' },
  { label: 'Malaysian Ringgit (MYR)', value: 'MYR' },
  { label: 'Mauritian Rupee (MUR)', value: 'MUR' },
  { label: 'Mexican Peso (MXN)', value: 'MXN' },
  { label: 'Moldovan Leu (MDL)', value: 'MDL' },
  { label: 'Mongolian Tugrik (MNT)', value: 'MNT' },
  { label: 'Moroccan Dirham (MAD)', value: 'MAD' },
  { label: 'Mozambican Metical (MZN)', value: 'MZN' },
  { label: 'Nepalese Rupee (NPR)', value: 'NPR' },
  { label: 'New Zealand Dollar (NZD)', value: 'NZD' },
  { label: 'Nigerian Naira (NGN)', value: 'NGN' },
  { label: 'Norwegian Krone (NOK)', value: 'NOK' },
  { label: 'Pakistani Rupee (PKR)', value: 'PKR' },
  { label: 'Philippine Peso (PHP)', value: 'PHP' },
  { label: 'Russian Ruble (RUB)', value: 'RUB' },
  { label: 'Singapore Dollar (SGD)', value: 'SGD' },
  { label: 'South African Rand (ZAR)', value: 'ZAR' },
  { label: 'South Korean Won (KRW)', value: 'KRW' },
  { label: 'Swiss Franc (CHF)', value: 'CHF' },
  { label: 'Turkish Lira (TRY)', value: 'TRY' },
  { label: 'United States Dollar (USD)', value: 'USD' },
  { label: 'Vietnamese Dong (VND)', value: 'VND' },
]

export const reportsToOptions = [
  {
    Civil: [
      {
        label: 'Project Manager',
        value: 'Project Manager',
      },
      {
        label: 'Planning Engineer',
        value: 'Planning Engineer',
      },
      {
        label: 'Design Engineer',
        value: 'Design Engineer',
      },
      {
        label: 'Estimation Engineer',
        value: 'Estimation Engineer',
      },
      {
        label: 'Construction Manager',
        value: 'Construction Manager',
      },
      {
        label: 'Architect',
        value: 'Architect',
      },
      {
        label: 'Quantity Surveyor',
        value: 'Quantity Surveyor',
      },
      {
        label: 'Land Surveyor',
        value: 'Land Surveyor',
      },
      {
        label: 'Structural Engineer',
        value: 'Structural Engineer',
      },
      {
        label: 'MEP Engineer',
        value: 'MEP Engineer',
      },
      {
        label: 'Electrical Engineer',
        value: 'Electrical Engineer',
      },
      {
        label: 'Civil Engineer',
        value: 'Civil Engineer',
      },
      {
        label: 'HVAC Engineer',
        value: 'HVAC Engineer',
      },
      {
        label: 'Site Supervisor',
        value: 'Site Supervisor',
      },
      {
        label: 'Site Foreman',
        value: 'Site Foreman',
      },
      {
        label: 'Safety Officer',
        value: 'Safety Officer',
      },
      {
        label: 'QA QC Engineer',
        value: 'QA QC Engineer',
      },
      {
        label: 'QA QC Inspector',
        value: 'QA QC Inspector',
      },
      {
        label: 'Autocad Designer / Draftsman',
        value: 'Autocad Designer / Draftsman',
      },
      {
        label: 'Electrician',
        value: 'Electrician',
      },
      {
        label: 'Welder (Construction)',
        value: 'Welder (Construction)',
      },
      {
        label: 'HVA Technician',
        value: 'HVA Technician',
      },
      {
        label: 'Bar Bender / RCC',
        value: 'Bar Bender / RCC',
      },
      {
        label: 'Shuttering Carpenter',
        value: 'Shuttering Carpenter',
      },
      {
        label: 'Masonery (Tiles, Mason)',
        value: 'Masonery (Tiles, Mason)',
      },
      {
        label: 'Painter',
        value: 'Painter',
      },
      {
        label: 'Plumber',
        value: 'Plumber',
      },
      {
        label: 'Wall Plaster',
        value: 'Wall Plaster',
      },
      {
        label: 'Heavy Equipment Driver/Opr (JCB, Bulldozer, Crane Operator)',
        value: 'Heavy Equipment Driver/Opr (JCB, Bulldozer, Crane Operator)',
      },
      {
        label: 'Excavator',
        value: 'Excavator',
      },
      {
        label: 'Unskilled Workers',
        value: 'Unskilled Workers',
      },
    ],
  },
  {
    Electrical: [
      {
        label: 'Project Manager Instrumentation/Electronics/Telecon',
        value: 'Project Manager Instrumentation/Electronics/Telecon',
      },
      {
        label: 'Planning Engineer Instrumentation/Electronics/Telecon',
        value: 'Planning Engineer Instrumentation/Electronics/Telecon',
      },
      {
        label: 'Design Engineer Instrumentation/Electronics/Telecon',
        value: 'Design Engineer Instrumentation/Electronics/Telecon',
      },
      {
        label: 'Estimation Engineer Instrumentation/Electronics/Telecon',
        value: 'Estimation Engineer Instrumentation/Electronics/Telecon',
      },
      {
        label:
          'Commissioning Engn / Manager- Instrumentation/Electronics/Telecon',
        value:
          'Commissioning Engn / Manager- Instrumentation/Electronics/Telecon',
      },
      {
        label: 'Quantity Surveyor Instrumentation/Electronics/Telecon',
        value: 'Quantity Surveyor Instrumentation/Electronics/Telecon',
      },
      {
        label: 'Electrical Engineer',
        value: 'Electrical Engineer',
      },
      {
        label:
          'QA QC Engineer / Inspector Instrumentation/Electronics/TeleconQA',
        value:
          'QA QC Engineer / Inspector Instrumentation/Electronics/TeleconQA',
      },
      {
        label: 'QC Inspector Instrumentation/Electronics/Telecon',
        value: 'QC Inspector Instrumentation/Electronics/Telecon',
      },
      {
        label: 'Instrumentation & Control Engineer',
        value: 'Instrumentation & Control Engineer',
      },
      {
        label: 'Site Supervisor Instrumentation/Electronics/Telecon',
        value: 'Site Supervisor Instrumentation/Electronics/Telecon',
      },
      {
        label: 'Site Foreman Instrumentation/Electronics/Telecon',
        value: 'Site Foreman Instrumentation/Electronics/Telecon',
      },
      {
        label: 'Safety Officer Instrumentation/Electronics/Telecon',
        value: 'Safety Officer Instrumentation/Electronics/Telecon',
      },
      {
        label: 'Telecom Field Engineer',
        value: 'Telecom Field Engineer',
      },
      {
        label: 'Technician-Electrical / Instrument./Electronics/Telecon',
        value: 'Technician-Electrical / Instrument//Electronics/Telecon',
      },
      {
        label: 'Light Special Effects Technician',
        value: 'Light Special Effects Technician',
      },
      {
        label: 'Telecom Fiber Optic Technician',
        value: 'Telecom Fiber Optic Technician',
      },
      {
        label: 'Fiber Optic Cable Technician',
        value: 'Fiber Optic Cable Technician',
      },
      {
        label: 'Control Panel Technician',
        value: 'Control Panel Technician',
      },
      {
        label: 'Electrician',
        value: 'Electrician',
      },
      {
        label: 'Auto Electrician',
        value: 'Auto Electrician',
      },
      {
        label: 'Autocad Electrical / Instrument./Electronics/Telecon',
        value: 'Autocad Electrical Instrument Electronics Telecon',
      },
      {
        label: 'CAD Technician',
        value: 'CAD Technician',
      },
    ],
  },
  {
    Mechanical: [
      {
        label: 'Project Manager Mechanical',
        value: 'ProjectManagerMechanical',
      },
      {
        label: 'Planning Engineer Mechanical',
        value: 'PlanningEngineerMechanical',
      },
      {
        label: 'Design Engineer Mechanical',
        value: 'DesignEngineerMechanical',
      },
      {
        label: 'Estimation Engineer Mechanical',
        value: 'EstimationEngineerMechanical',
      },
      {
        label: 'Commissioning Engineer',
        value: 'CommissioningEngineer',
      },
      {
        label: 'Quantity Surveyor Mechanical',
        value: 'QuantitySurveyorMechanical',
      },
      {
        label: 'QA QC Engineer / Inspector Mechanical',
        value: 'QAQCEngineerInspectorMechanical',
      },
      {
        label: 'Painting & Coating Engineer (B Gas, Nace)',
        value: 'PaintingCoatingEngineerBGasNace',
      },
      {
        label: 'HVAC Engineer',
        value: 'HVACEngineer',
      },
      {
        label: 'Site Supervisor / Foreman Mechanical',
        value: 'SiteSupervisorForemanMechanical',
      },
      {
        label: 'Safety Officer Mechanical',
        value: 'SafetyOfficerMechanical',
      },
      {
        label: 'Mechatronics Engineer',
        value: 'MechatronicsEngineer',
      },
      {
        label: 'Technical Sales Engineer',
        value: 'TechnicalSalesEngineer',
      },
      {
        label: 'Mechanical Technician',
        value: 'MechanicalTechnician',
      },
      {
        label: 'Machine Operator',
        value: 'MachineOperator',
      },
      {
        label: 'HVAC Technician',
        value: 'HVACTechnician',
      },
      {
        label: 'Automation Technician',
        value: 'AutomationTechnician',
      },
      {
        label: 'CAD Technician (Draftsman)',
        value: 'CADTechnicianDraftsman',
      },
      {
        label: 'Plant Mechanic',
        value: 'PlantMechanic',
      },
      {
        label: 'Auto Mechanic',
        value: 'AutoMechanic',
      },
      {
        label: 'Heavy Equipment Mechanic',
        value: 'HeavyEquipmentMechanic',
      },
    ],
  },
  {
    'INFORMATION TECHNOLOGY': [
      {
        label: 'Application Software Engineer',
        value: 'ApplicationSoftwareEngineer',
      },
      {
        label: 'System Software Engineer',
        value: 'SystemSoftwareEngineer',
      },
      {
        label: 'Cloud Architect',
        value: 'CloudArchitect',
      },
      {
        label: 'Security Engineer',
        value: 'SecurityEngineer',
      },
      {
        label: 'Back-End Engineer',
        value: 'BackEndEngineer',
      },
      {
        label: 'DevOps Engineer',
        value: 'DevOpsEngineer',
      },
      {
        label: 'Software Development Engineer (Coding)',
        value: 'SoftwareDevelopmentEngineerCoding',
      },
      {
        label: 'Software Development Engineer (Test)',
        value: 'SoftwareDevelopmentEngineerTest',
      },
      {
        label: 'Data Engineer',
        value: 'DataEngineer',
      },
      {
        label: 'Video Game Programmer',
        value: 'VideoGameProgrammer',
      },
      {
        label: 'CRM Project Manager',
        value: 'CRMProjectManager',
      },
      {
        label: 'Software Integration Engineer',
        value: 'SoftwareIntegrationEngineer',
      },
      {
        label: 'Hardware Design Engineer',
        value: 'HardwareDesignEngineer',
      },
      {
        label: 'IT Hardware Engineer (Computer)',
        value: 'ITHardwareEngineerComputer',
      },
      {
        label: 'Server Hardware Engineer',
        value: 'ServerHardwareEngineer',
      },
      {
        label: 'Computer Hardware Repair',
        value: 'ComputerHardwareRepair',
      },
      {
        label: 'Network Administrator',
        value: 'NetworkAdministrator',
      },
      {
        label: 'Network Technician',
        value: 'NetworkTechnician',
      },
    ],
  },
]

export const reportsToOptions2 = [
  { label: 'CXO', value: 'cxo' },
  { label: 'President', value: 'President' },
  { label: 'Vice President (VP)', value: 'Vice President (VP)' },
  { label: 'Director', value: 'Director' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Supervisor', value: 'Supervisor' },
  { label: 'Team Lead', value: 'Team Lead' },
  { label: 'Employee', value: 'Employee' },
]
export const deptOptions = [
  { label: 'Human Resources (HR)', value: 'Human Resources (HR)' },
  { label: 'Operations', value: 'Operations' },
  { label: 'Safety and Compliance', value: 'Safety and Compliance' },
  { label: 'Quality Control/Assurance', value: 'Quality Control/Assurance' },
  { label: 'Maintenance', value: 'Maintenance' },
  { label: 'Supply Chain and Logistics', value: 'Supply Chain and Logistics' },
  { label: 'Customer Service', value: 'Customer Service' },
  { label: 'Finance and Accounting', value: 'Finance and Accounting' },
  { label: 'Training and Development', value: 'Training and Development' },
  { label: 'Marketing and Sales', value: 'Marketing and Sales' },
  {
    label: 'Information Technology (IT)',
    value: 'Information Technology (IT)',
  },
  { label: 'Administration', value: 'Administration' },
]

export const statusOption = [
  { label: 'Active', value: 'active' },
  { label: 'On hold', value: 'On hold' },
  { label: 'Resolved', value: 'resolved' },
]
export const reasonOption = [
  { label: 'Application status', value: 'Application status' },
  { label: 'Job Inquiry', value: 'Job Inquiry' },
  { label: 'Selection Inquiry', value: 'Selection Inquiry' },
  { label: 'Visa Inquiry', value: 'Visa Inquiry' },
  { label: 'Travel Inquiry', value: 'Travel Inquiry' },
  { label: 'Other', value: 'Other' },
]

export const uploadDocumentOption = [
  { label: 'Pancard', value: 'pancard' },
  { label: 'Aadhar Card', value: 'aadharcard' },
  { label: 'Passport', value: 'passport' },
  { label: 'Passport Image', value: 'passportimage' },
  { label: 'Profile Picture', value: 'profilepic' },
  { label: 'CV', value: 'cv' },
]

export const countryOption = [
  {
    label: 'Iran',
    value: 'Iran',
  },
  {
    label: 'Kuwait',
    value: 'Kuwait',
  },
  {
    label: 'Oman',
    value: 'Oman',
  },
  {
    label: 'Qatar',
    value: 'Qatar',
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia',
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates',
  },
  {
    label: 'Afghanistan',
    value: 'Afghanistan',
  },
  {
    label: 'Åland Islands',
    value: 'Åland Islands',
  },
  {
    label: 'Albania',
    value: 'Albania',
  },
  {
    label: 'Algeria',
    value: 'Algeria',
  },
  {
    label: 'American Samoa',
    value: 'American Samoa',
  },
  {
    label: 'Andorra',
    value: 'Andorra',
  },
  {
    label: 'Angola',
    value: 'Angola',
  },
  {
    label: 'Anguilla',
    value: 'Anguilla',
  },
  {
    label: 'Antarctica',
    value: 'Antarctica',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
  },
  {
    label: 'Argentina',
    value: 'Argentina',
  },
  {
    label: 'Armenia',
    value: 'Armenia',
  },
  {
    label: 'Aruba',
    value: 'Aruba',
  },
  {
    label: 'Australia',
    value: 'Australia',
  },
  {
    label: 'Austria',
    value: 'Austria',
  },
  {
    label: 'Azerbaijan',
    value: 'Azerbaijan',
  },
  {
    label: 'Bahamas',
    value: 'Bahamas',
  },
  {
    label: 'Bahrain',
    value: 'Bahrain',
  },
  {
    label: 'Bangladesh',
    value: 'Bangladesh',
  },
  {
    label: 'Barbados',
    value: 'Barbados',
  },
  {
    label: 'Belarus',
    value: 'Belarus',
  },
  {
    label: 'Belgium',
    value: 'Belgium',
  },
  {
    label: 'Belize',
    value: 'Belize',
  },
  {
    label: 'Benin',
    value: 'Benin',
  },
  {
    label: 'Bermuda',
    value: 'Bermuda',
  },
  {
    label: 'Bhutan',
    value: 'Bhutan',
  },
  {
    label: 'Bolivia (Plurinational State of)',
    value: 'Bolivia (Plurinational State of)',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
  },
  {
    label: 'Botswana',
    value: 'Botswana',
  },
  {
    label: 'Bouvet Island',
    value: 'Bouvet Island',
  },
  {
    label: 'Brazil',
    value: 'Brazil',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
  },
  {
    label: 'Brunei Darussalam',
    value: 'Brunei Darussalam',
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria',
  },
  {
    label: 'Burkina Faso',
    value: 'Burkina Faso',
  },
  {
    label: 'Burundi',
    value: 'Burundi',
  },
  {
    label: 'Cabo Verde',
    value: 'Cabo Verde',
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
  },
  {
    label: 'Cameroon',
    value: 'Cameroon',
  },
  {
    label: 'Canada',
    value: 'Canada',
  },
  {
    label: 'Cayman Islands',
    value: 'Cayman Islands',
  },
  {
    label: 'Central African Republic',
    value: 'Central African Republic',
  },
  {
    label: 'Chad',
    value: 'Chad',
  },
  {
    label: 'Chile',
    value: 'Chile',
  },
  {
    label: 'China',
    value: 'China',
  },
  {
    label: 'Christmas Island',
    value: 'Christmas Island',
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'Cocos (Keeling) Islands',
  },
  {
    label: 'Colombia',
    value: 'Colombia',
  },
  {
    label: 'Comoros',
    value: 'Comoros',
  },
  {
    label: 'Congo (the Democratic Republic of the)',
    value: 'Congo (the Democratic Republic of the)',
  },
  {
    label: 'Congo',
    value: 'Congo',
  },
  {
    label: 'Cook Islands',
    value: 'Cook Islands',
  },
  {
    label: 'Costa Rica',
    value: 'Costa Rica',
  },
  {
    label: 'Croatia',
    value: 'Croatia',
  },
  {
    label: 'Cuba',
    value: 'Cuba',
  },
  {
    label: 'Curaçao',
    value: 'Curaçao',
  },
  {
    label: 'Cyprus',
    value: 'Cyprus',
  },
  {
    label: 'Czechia',
    value: 'Czechia',
  },
  {
    label: "Côte d'Ivoire",
    value: "Côte d'Ivoire",
  },
  {
    label: 'Denmark',
    value: 'Denmark',
  },
  {
    label: 'Djibouti',
    value: 'Djibouti',
  },
  {
    label: 'Dominica',
    value: 'Dominica',
  },
  {
    label: 'Dominican Republic',
    value: 'Dominican Republic',
  },
  {
    label: 'Ecuador',
    value: 'Ecuador',
  },
  {
    label: 'Egypt',
    value: 'Egypt',
  },
  {
    label: 'El Salvador',
    value: 'El Salvador',
  },
  {
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
  },
  {
    label: 'Eritrea',
    value: 'Eritrea',
  },
  {
    label: 'Estonia',
    value: 'Estonia',
  },
  {
    label: 'Eswatini',
    value: 'Eswatini',
  },
  {
    label: 'Ethiopia',
    value: 'Ethiopia',
  },
  {
    label: 'Falkland Islands [Malvinas]',
    value: 'Falkland Islands [Malvinas]',
  },
  {
    label: 'Faroe Islands',
    value: 'Faroe Islands',
  },
  {
    label: 'Fiji',
    value: 'Fiji',
  },
  {
    label: 'Finland',
    value: 'Finland',
  },
  {
    label: 'France',
    value: 'France',
  },
  {
    label: 'French Guiana',
    value: 'French Guiana',
  },
  {
    label: 'French Polynesia',
    value: 'French Polynesia',
  },
  {
    label: 'French Southern Territories',
    value: 'French Southern Territories',
  },
  {
    label: 'Gabon',
    value: 'Gabon',
  },
  {
    label: 'Gambia',
    value: 'Gambia',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Germany',
    value: 'Germany',
  },
  {
    label: 'Ghana',
    value: 'Ghana',
  },
  {
    label: 'Gibraltar',
    value: 'Gibraltar',
  },
  {
    label: 'Greece',
    value: 'Greece',
  },
  {
    label: 'Greenland',
    value: 'Greenland',
  },
  {
    label: 'Grenada',
    value: 'Grenada',
  },
  {
    label: 'Guadeloupe',
    value: 'Guadeloupe',
  },
  {
    label: 'Guam',
    value: 'Guam',
  },
  {
    label: 'Guatemala',
    value: 'Guatemala',
  },
  {
    label: 'Guernsey',
    value: 'Guernsey',
  },
  {
    label: 'Guinea',
    value: 'Guinea',
  },
  {
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
  },
  {
    label: 'Guyana',
    value: 'Guyana',
  },
  {
    label: 'Haiti',
    value: 'Haiti',
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'Heard Island and McDonald Islands',
  },
  {
    label: 'Holy See',
    value: 'Holy See',
  },
  {
    label: 'Honduras',
    value: 'Honduras',
  },
  {
    label: 'Hong Kong',
    value: 'Hong Kong',
  },
  {
    label: 'Hungary',
    value: 'Hungary',
  },
  {
    label: 'Iceland',
    value: 'Iceland',
  },
  {
    label: 'India',
    value: 'India',
  },
  {
    label: 'Indonesia',
    value: 'Indonesia',
  },
  {
    label: 'Iran (Islamic Republic of)',
    value: 'Iran (Islamic Republic of)',
  },
  {
    label: 'Iraq',
    value: 'Iraq',
  },
  {
    label: 'Ireland',
    value: 'Ireland',
  },
  {
    label: 'Isle of Man',
    value: 'Isle of Man',
  },
  {
    label: 'Israel',
    value: 'Israel',
  },
  {
    label: 'Italy',
    value: 'Italy',
  },
  {
    label: 'Jamaica',
    value: 'Jamaica',
  },
  {
    label: 'Japan',
    value: 'Japan',
  },
  {
    label: 'Jersey',
    value: 'Jersey',
  },
  {
    label: 'Jordan',
    value: 'Jordan',
  },
  {
    label: 'Kazakhstan',
    value: 'Kazakhstan',
  },
  {
    label: 'Kenya',
    value: 'Kenya',
  },
  {
    label: 'Kiribati',
    value: 'Kiribati',
  },
  {
    label: "Korea (the Democratic People's Republic of)",
    value: "Korea (the Democratic People's Republic of)",
  },
  {
    label: 'Korea (the Republic of)',
    value: 'Korea (the Republic of)',
  },
  {
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
  },
  {
    label: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
  },
  {
    label: 'Latvia',
    value: 'Latvia',
  },
  {
    label: 'Lebanon',
    value: 'Lebanon',
  },
  {
    label: 'Lesotho',
    value: 'Lesotho',
  },
  {
    label: 'Liberia',
    value: 'Liberia',
  },
  {
    label: 'Libya',
    value: 'Libya',
  },
  {
    label: 'Liechtenstein',
    value: 'Liechtenstein',
  },
  {
    label: 'Lithuania',
    value: 'Lithuania',
  },
  {
    label: 'Luxembourg',
    value: 'Luxembourg',
  },
  {
    label: 'Macao',
    value: 'Macao',
  },
  {
    label: 'Madagascar',
    value: 'Madagascar',
  },
  {
    label: 'Malawi',
    value: 'Malawi',
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
  },
  {
    label: 'Maldives',
    value: 'Maldives',
  },
  {
    label: 'Mali',
    value: 'Mali',
  },
  {
    label: 'Malta',
    value: 'Malta',
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands',
  },
  {
    label: 'Martinique',
    value: 'Martinique',
  },
  {
    label: 'Mauritania',
    value: 'Mauritania',
  },
  {
    label: 'Mauritius',
    value: 'Mauritius',
  },
  {
    label: 'Mayotte',
    value: 'Mayotte',
  },
  {
    label: 'Mexico',
    value: 'Mexico',
  },
  {
    label: 'Micronesia (Federated States of)',
    value: 'Micronesia (Federated States of)',
  },
  {
    label: 'Moldova (the Republic of)',
    value: 'Moldova (the Republic of)',
  },
  {
    label: 'Monaco',
    value: 'Monaco',
  },
  {
    label: 'Mongolia',
    value: 'Mongolia',
  },
  {
    label: 'Montenegro',
    value: 'Montenegro',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
  },
  {
    label: 'Morocco',
    value: 'Morocco',
  },
  {
    label: 'Mozambique',
    value: 'Mozambique',
  },
  {
    label: 'Myanmar',
    value: 'Myanmar',
  },
  {
    label: 'Namibia',
    value: 'Namibia',
  },
  {
    label: 'Nauru',
    value: 'Nauru',
  },
  {
    label: 'Nepal',
    value: 'Nepal',
  },
  {
    label: 'Netherlands',
    value: 'Netherlands',
  },
  {
    label: 'New Caledonia',
    value: 'New Caledonia',
  },
  {
    label: 'New Zealand',
    value: 'New Zealand',
  },
  {
    label: 'Nicaragua',
    value: 'Nicaragua',
  },
  {
    label: 'Niger',
    value: 'Niger',
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
  },
  {
    label: 'Niue',
    value: 'Niue',
  },
  {
    label: 'Norfolk Island',
    value: 'Norfolk Island',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
  },
  {
    label: 'Norway',
    value: 'Norway',
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
  },
  {
    label: 'Palau',
    value: 'Palau',
  },
  {
    label: 'Palestine, State of',
    value: 'Palestine, State of',
  },
  {
    label: 'Panama',
    value: 'Panama',
  },
  {
    label: 'Papua New Guinea',
    value: 'Papua New Guinea',
  },
  {
    label: 'Paraguay',
    value: 'Paraguay',
  },
  {
    label: 'Peru',
    value: 'Peru',
  },
  {
    label: 'Philippines',
    value: 'Philippines',
  },
  {
    label: 'Pitcairn',
    value: 'Pitcairn',
  },
  {
    label: 'Poland',
    value: 'Poland',
  },
  {
    label: 'Portugal',
    value: 'Portugal',
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
  },
  {
    label: 'Republic of North Macedonia',
    value: 'Republic of North Macedonia',
  },
  {
    label: 'Romania',
    value: 'Romania',
  },
  {
    label: 'Russian Federation',
    value: 'Russian Federation',
  },
  {
    label: 'Rwanda',
    value: 'Rwanda',
  },
  {
    label: 'Réunion',
    value: 'Réunion',
  },
  {
    label: 'Saint Barthélemy',
    value: 'Saint Barthélemy',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
  },
  {
    label: 'Saint Lucia',
    value: 'Saint Lucia',
  },
  {
    label: 'Saint Martin (French part)',
    value: 'Saint Martin (French part)',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  {
    label: 'Samoa',
    value: 'Samoa',
  },
  {
    label: 'San Marino',
    value: 'San Marino',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
  },
  {
    label: 'Senegal',
    value: 'Senegal',
  },
  {
    label: 'Serbia',
    value: 'Serbia',
  },
  {
    label: 'Seychelles',
    value: 'Seychelles',
  },
  {
    label: 'Sierra Leone',
    value: 'Sierra Leone',
  },
  {
    label: 'Singapore',
    value: 'Singapore',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'Sint Maarten (Dutch part)',
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
  },
  {
    label: 'Solomon Islands',
    value: 'Solomon Islands',
  },
  {
    label: 'Somalia',
    value: 'Somalia',
  },
  {
    label: 'South Africa',
    value: 'South Africa',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
  },
  {
    label: 'South Sudan',
    value: 'South Sudan',
  },
  {
    label: 'Spain',
    value: 'Spain',
  },
  {
    label: 'Sri Lanka',
    value: 'Sri Lanka',
  },
  {
    label: 'Sudan',
    value: 'Sudan',
  },
  {
    label: 'Suriname',
    value: 'Suriname',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
  },
  {
    label: 'Sweden',
    value: 'Sweden',
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'Syrian Arab Republic',
  },
  {
    label: 'Taiwan (Province of China)',
    value: 'Taiwan (Province of China)',
  },
  {
    label: 'Tajikistan',
    value: 'Tajikistan',
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'Tanzania, United Republic of',
  },
  {
    label: 'Thailand',
    value: 'Thailand',
  },
  {
    label: 'Timor-Leste',
    value: 'Timor-Leste',
  },
  {
    label: 'Togo',
    value: 'Togo',
  },
  {
    label: 'Tokelau',
    value: 'Tokelau',
  },
  {
    label: 'Tonga',
    value: 'Tonga',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
  },
  {
    label: 'Tunisia',
    value: 'Tunisia',
  },
  {
    label: 'Turkey',
    value: 'Turkey',
  },
  {
    label: 'Turkmenistan',
    value: 'Turkmenistan',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
  },
  {
    label: 'Tuvalu',
    value: 'Tuvalu',
  },
  {
    label: 'Uganda',
    value: 'Uganda',
  },
  {
    label: 'Ukraine',
    value: 'Ukraine',
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'United Kingdom of Great Britain and Northern Ireland',
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'United States Minor Outlying Islands',
  },
  {
    label: 'United States of America',
    value: 'United States of America',
  },
  {
    label: 'Uruguay',
    value: 'Uruguay',
  },
  {
    label: 'Uzbekistan',
    value: 'Uzbekistan',
  },
  {
    label: 'Vanuatu',
    value: 'Vanuatu',
  },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    value: 'Venezuela (Bolivarian Republic of)',
  },
  {
    label: 'Viet Nam',
    value: 'Viet Nam',
  },
  {
    label: 'Virgin Islands (British)',
    value: 'Virgin Islands (British)',
  },
  {
    label: 'Virgin Islands (U.S.)',
    value: 'Virgin Islands (U.S.)',
  },
  {
    label: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
  },
  {
    label: 'Western Sahara',
    value: 'Western Sahara',
  },
  {
    label: 'Yemen',
    value: 'Yemen',
  },
  {
    label: 'Zambia',
    value: 'Zambia',
  },
  {
    label: 'Zimbabwe',
    value: 'Zimbabwe',
  },
]

export const progressOption = [
  { label: 'In Progress', value: 'inprogress' },
  { label: 'Completed', value: 'completed' },
  { label: 'Incompleted', value: 'incompleted' },
]
export const contentType = {
  image: 'image/*',
  pdf: 'application/pdf',
  file: 'application/octet-stream',
}

export const realtionOption = [
  { label: 'father', value: 'father' },
  { label: 'mother', value: 'mother' },
  { label: 'brother', value: 'brother' },
  { label: 'sister', value: 'sister' },
]

export const tradeOptions = [
  { value: 'Project Manager', label: 'Project Manager' },
  { value: 'Planning Engineer', label: 'Planning Engineer' },
  { value: 'Design Engineer', label: 'Design Engineer' },
  { value: 'Estimation Engineer', label: 'Estimation Engineer' },
  { value: 'Construction Manager', label: 'Construction Manager' },
  { value: 'Architect', label: 'Architect' },
  { value: 'Quantity Surveyor', label: 'Quantity Surveyor' },
  { value: 'Land Surveyor', label: 'Land Surveyor' },
  { value: 'Structural Engineer', label: 'Structural Engineer' },
  { value: 'MEP Engineer', label: 'MEP Engineer' },
  { value: 'Electrical Engineer', label: 'Electrical Engineer' },
  { value: 'Civil Engineer', label: 'Civil Engineer' },
  { value: 'HVAC Engineer', label: 'HVAC Engineer' },
  { value: 'Site Supervisor', label: 'Site Supervisor' },
  { value: 'Site Foreman', label: 'Site Foreman' },
  { value: 'Safety Officer', label: 'Safety Officer' },
  { value: 'QA QC Engineer', label: 'QA QC Engineer' },
  { value: 'QA QC Inspector', label: 'QA QC Inspector' },

  {
    value: 'Autocad Designer / Draftsman',
    label: 'Autocad Designer / Draftsman',
  },
  { value: 'Electrician', label: 'Electrician' },
  { value: 'Welder (Construction)', label: 'Welder (Construction)' },
  { value: 'HVA Technician', label: 'HVA Technician' },
  { value: 'Bar Bender / RCC', label: 'Bar Bender / RCC' },
  { value: 'Shuttering Carpenter', label: 'Shuttering Carpenter' },
  { value: 'Masonery (Tiles, Mason)', label: 'Masonery (Tiles, Mason)' },
  { value: 'Painter', label: 'Painter' },
  { value: 'Plumber', label: 'Plumber' },
  { value: 'Wall Plaster', label: 'Wall Plaster' },
  {
    value: 'Heavy Equipment Driver/Opr (jcb, bulldozer, crane operator)',
    label: 'Heavy Equipment Driver/Opr (jcb, bulldozer, crane operator)',
  },
  { value: 'Excavator', label: 'Excavator' },
  { value: 'Unskilled workers', label: 'Unskilled workers' },

  {
    value: 'Project Manager Instrumentation/Electronics/Telecon',
    label: 'Project Manager Instrumentation/Electronics/Telecon',
  },
  {
    value: 'Planning Engineer Instrumentation/Electronics/Telecon',
    label: 'Planning Engineer Instrumentation/Electronics/Telecon',
  },
  {
    value: 'Design Engineer Instrumentation/Electronics/Telecon',
    label: 'Design Engineer Instrumentation/Electronics/Telecon',
  },
  {
    value: 'Estimation Engineer Instrumentation/Electronics/Telecon',
    label: 'Estimation Engineer Instrumentation/Electronics/Telecon',
  },
  {
    value: 'Commissioning Engn / Manager- Instrumentation/Electronics/Telecon',
    label: 'Commissioning Engn / Manager- Instrumentation/Electronics/Telecon',
  },
  {
    value: 'Quantity Surveyor Instrumentation/Electronics/Telecon',
    label: 'Quantity Surveyor Instrumentation/Electronics/Telecon',
  },
  {
    value: 'QA QC Engineer / Inspector Instrumentation/Electronics/Telecon',
    label: 'QA QC Engineer / Inspector Instrumentation/Electronics/Telecon',
  },
  {
    value: 'QC Inspector Instrumentation/Electronics/Telecon',
    label: 'QC Inspector Instrumentation/Electronics/Telecon',
  },
  {
    value: 'Instrumentation & Control Engineer',
    label: 'Instrumentation & Control Engineer',
  },
  {
    value: 'Site Supervisor Instrumentation/Electronics/Telecon',
    label: 'Site Supervisor Instrumentation/Electronics/Telecon',
  },
  {
    value: 'Site Foreman Instrumentation/Electronics/Telecon',
    label: 'Site Foreman Instrumentation/Electronics/Telecon',
  },
  {
    value: 'Safety Officer Instrumentation/Electronics/Telecon',
    label: 'Safety Officer Instrumentation/Electronics/Telecon',
  },
  { value: 'Telecom Field Enginner', label: 'Telecom Field Enginner' },

  {
    value: 'Technician-Electrical / Instrument./Electronics/Telecon',
    label: 'Technician-Electrical / Instrument./Electronics/Telecon',
  },
  {
    value: 'Light special effects Technician',
    label: 'Light special effects Technician',
  },
  {
    value: 'Telecom Fiber Optic Technician',
    label: 'Telecom Fiber Optic Technician',
  },
  {
    value: 'Fiber Optic Cable Tecnician',
    label: 'Fiber Optic Cable Tecnician',
  },
  { value: 'Control Panel Technician', label: 'Control Panel Technician' },
  { value: 'Auto Electrician', label: 'Auto Electrician' },
  {
    value: 'Autocad Electrical / Instrument./Electronics/Telecon',
    label: 'Autocad Electrical / Instrument./Electronics/Telecon',
  },
  { value: 'CAD technician', label: 'CAD technician' },

  { value: 'Project Manager Mechanical', label: 'Project Manager Mechanical' },
  {
    value: 'Planning Engineer Mechanical',
    label: 'Planning Engineer Mechanical',
  },
  { value: 'Design Engineer Mechanical', label: 'Design Engineer Mechanical' },
  {
    value: 'Estimation Engineer Mechanical',
    label: 'Estimation Engineer Mechanical',
  },
  { value: 'Commissioning Engineer', label: 'Commissioning Engineer' },
  {
    value: 'Quantity Surveyor Mechanical',
    label: 'Quantity Surveyor Mechanical',
  },
  {
    value: 'QA QC Engineer / Inspector Mechanical',
    label: 'QA QC Engineer / Inspector Mechanical',
  },
  {
    value: 'Painting & Coating Engineer (B Gas, Nace)',
    label: 'Painting & Coating Engineer (B Gas, Nace)',
  },
  { value: 'HVAC Engineer', label: 'HVAC Engineer' },
  {
    value: 'Site Supervisor / Foreman Mechanical',
    label: 'Site Supervisor / Foreman Mechanical',
  },
  { value: 'Safety Officer Mechanical', label: 'Safety Officer Mechanical' },
  { value: 'Mechatronics Engineer', label: 'Mechatronics Engineer' },
  { value: 'Technical Sales Engineer', label: 'Technical Sales Engineer' },

  { value: 'Mechanical Technician', label: 'Mechanical Technician' },
  { value: 'Mechine Operator', label: 'Mechine Operator' },
  { value: 'Automation Technician', label: 'Automation Technician' },
  { value: 'Plant Mechanic', label: 'Plant Mechanic' },
  { value: 'Auto Mechanic', label: 'Auto Mechanic' },
  { value: 'Heavy Equipment Mechanic', label: 'Heavy Equipment Mechanic' },

  {
    value: 'Application Software Engineer',
    label: 'Application Software Engineer',
  },
  { value: 'System Software Engineer', label: 'System Software Engineer' },
  { value: 'Cloud Architect', label: 'Cloud Architect' },
  { value: 'Security Engineer', label: 'Security Engineer' },
  { value: 'Back-End Engineer', label: 'Back-End Engineer' },
  { value: 'DevOps Engineer', label: 'DevOps Engineer' },
  {
    value: 'Software Development Engineer (Coding)',
    label: 'Software Development Engineer (Coding)',
  },
  {
    value: 'Software Development Engineer (Test)',
    label: 'Software Development Engineer (Test)',
  },
  { value: 'Data Engineer', label: 'Data Engineer' },
  { value: 'Video Game Programmer', label: 'Video Game Programmer' },
  { value: 'CRM Project Manager', label: 'CRM Project Manager' },
  {
    value: 'Software Integration Engineer',
    label: 'Software Integration Engineer',
  },

  { value: 'Hardware Design Engineer', label: 'Hardware Design Engineer' },
  {
    value: 'IT Hardware Engineer (Computer)',
    label: 'IT Hardware Engineer (Computer)',
  },
  { value: 'Server hardware Engineer', label: 'Server hardware Engineer' },
  { value: 'Computer Hardware Repair', label: 'Computer Hardware Repair' },
  { value: 'Network Administrator', label: 'Network Administrator' },
  { value: 'Network Technician', label: 'Network Technician' },
]
