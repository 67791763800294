import { LuDownload } from 'react-icons/lu'
import { Text } from 'components'
import { Link } from 'react-router-dom'

interface DownloadDocumentProps {
  url: string
  title: string
  name?: string
}

const DownloadDocument: React.FC<DownloadDocumentProps> = ({
  url,
  title,
  name,
}) => {
  const downloadFilename = name ? `${title}-${name}.pdf` : `${title}.pdf`

  return (
    <div className="bg-blue-50_01 min-h-[50px] flex items-center px-5 rounded-lg m-3">
      <Link
        to={url}
        download={downloadFilename}
        className="flex items-center justify-between w-full space-x-2"
        target="_blank"
      >
        <Text className="w-auto text-md" size="txtPoppinsMedium18">
          {title === 'cv' ? (
            <span className=" uppercase">CV</span>
          ) : title === 'profilepic' ? (
            <span className=" ">Profile Pic</span>
          ) : title === 'aadharcard' ? (
            <span className=" ">Aadhar Card</span>
          ) : (
            title
          )}
        </Text>
        <LuDownload />
      </Link>
    </div>
  )
}

export default DownloadDocument
