import ModalBase from 'modals/BaseModal'
import { Button, Text } from 'components'
import { ReactNode, useState } from 'react'
import { useMutation } from 'react-query'
import usePostRecurtment, { stages } from 'hooks/usePostRecurtment'
import { useForm } from 'react-hook-form'
import usePostRecurtmentContext from 'context/PostRecurtmentContext'
import toast from 'react-hot-toast'
import SendMessage from 'modals/SendMessage'
import { FaRegCalendarAlt } from 'react-icons/fa'
import useCandidate from 'hooks/useCandidate'

interface flyoutCheckList {
  children: ReactNode
  stages: stages[]
  defaultValues?: object
  applicantDetails?: any
}

const FlyoutCheckList = ({
  children,
  stages,
  defaultValues,
  applicantDetails,
}: flyoutCheckList) => {
  const { currentApplicant } = usePostRecurtmentContext()
  const { updateFlyoutCheckList } = usePostRecurtment()
  const form = useForm({
    defaultValues,
  })
  const [showModal, setShowModal] = useState(false)
  const { sendEmail } = useCandidate()

  const checkListHandler = useMutation({
    mutationKey: ['flyoutCheckList'],
    mutationFn: updateFlyoutCheckList,
    onError: (error: any) => {
      toast.dismiss()
      toast.error(error?.response?.data?.message)
    },
    onSuccess: () => {
      toast.dismiss()
    },
    onMutate: () => {
      toast.loading('Updating the checklist...')
    },
  })
  const messageHandler = useMutation({
    mutationKey: ['messageHandler'],
    mutationFn: sendEmail,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
  })

  const onsubmit = (data) => {
    const stages = Object.entries(data).map(([key, value]) => ({
      stage: key,
      value: Boolean(value),
    }))

    const allStagesTrue = stages.every((stage) => stage.value === true)

    const newData = {
      applicationId: currentApplicant.applicationid,
      stages,
    }

    checkListHandler.mutate(newData, {
      onSuccess: () => {
        if (allStagesTrue) {
          setShowModal(true)
          toast.success('Candidate is ready for departure')
        } else {
          toast.success('checklist has been updated')
        }
      },
    })
  }

  const emailHandler = (data: {
    content: string
    senderName: string
    senderId: string
  }) => {
    if (!currentApplicant?.applicationid) {
      toast.error('select the applicant first')
      return
    }
    const emailFormat = {
      name: data.senderName,
      email: data.senderId,
      subject: 'Congratulations! Your Application is Complete',
      content: `Hi ${data.senderName},  

Congratulations! We are delighted to inform you that your application for this role at AMBE Consultancy is now complete and ready for the next steps.  

You are now on the path for departure, and our team at AMBE Consultancy is ready to assist you further. If you have any questions or need additional support, please don't hesitate to reach out.  

Thank you for choosing AMBE Consultancy. We look forward to the next phase of your journey with us.`,
    }
    toast.promise(messageHandler.mutateAsync(emailFormat), {
      loading: 'sending the mail',
      error: 'Could not send the email to the candidate',
      success: 'Email sent successfully to the candidate',
    })
  }

  // console.log('applicantDetails 222', applicantDetails)
  const name =
    applicantDetails.name ??
    (applicantDetails.firstname && applicantDetails.lastname
      ? `${applicantDetails.firstname} ${applicantDetails.lastname}`
      : applicantDetails.firstname ?? applicantDetails.lastname ?? '')

  return (
    <>
      <ModalBase
        triggerComponent={children}
        modalClass={showModal ? 'max-w-[485px]' : 'max-w-[900px]'}
        acceptFunctions={form.handleSubmit(onsubmit)}
        isNeedAcceptButton={showModal ? false : true}
      >
        {!showModal && (
          <Text
            className="w-auto text-xl text-gray-900 capitalize"
            size="txtPoppinsMedium20Gray900"
          >
            Flyout Checklist
          </Text>
        )}
        {!showModal && (
          <Text size="txtPoppinsRegular11Black900" className="text-gray-500">
            Please review and select the documents received by the candidate.
          </Text>
        )}
        {showModal ? (
          <div className="flex flex-col w-full">
            <div className=" flex items-start justify-start gap-6 mb-8">
              <img src="/images/verification-yes.svg" alt="" />

              <Text className="text-[18px] text-[#3C3C3C] font-semibold mt-2">
                <span className=" capitalize">{name} </span> is <br />
                <span className="text-[#165FE3]">ready to departure</span>
              </Text>
            </div>
            <div>
              <Text className="text-sm text-gray-500 ">
                Closing this popup, will send a personalized email to the
                candidate at this email address.
              </Text>
              <div className="w-full bg-gray-100 rounded-md p-4 mt-2">
                <div className="flex justify-between text-sm mt-1">
                  <span className="text-gray-500">Whatsapp Number</span>
                  <span>
                    {applicantDetails.whatsapp_number
                      ? applicantDetails.whatsapp_number
                      : applicantDetails.mobile_number}
                  </span>
                </div>
                <div className="flex justify-between text-sm mt-1">
                  <span className="text-gray-500">Email</span>
                  <span>{applicantDetails?.email}</span>
                </div>
              </div>
            </div>
            <SendMessage
              primaryColor="orange"
              sendId={applicantDetails?.email}
              candidateName={applicantDetails?.firstname}
              sendfunction={emailHandler}
              needButton={false}
              showPopup={false}
            >
              <Button className="cursor-pointer flex items-center justify-center min-w-[105px] px-[15px] py-[13px] rounded-[10px] text-white-A700 bg-blue-500 float-right mt-4">
                <div className="font-medium leading-[normal] text-left text-sm text-white-A700 capitalize">
                  Send Message
                </div>
              </Button>
            </SendMessage>
          </div>
        ) : (
          <div className="w-full ">
            {stages.map(({ stage, flyout_checklist }) => (
              <div
                className="flex items-center w-full gap-3 p-3 border cursor-pointer"
                key={stage}
              >
                <input
                  name={stage}
                  {...form.register(stage as never)}
                  type="checkbox"
                  defaultChecked={!!flyout_checklist}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <Text>{stage}</Text>
              </div>
            ))}
          </div>
        )}
      </ModalBase>
    </>
  )
}

export default FlyoutCheckList
