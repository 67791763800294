import StatsBox from 'components/StatsBox'
import { CloseSVG } from 'assets/images'
import { Img, Text, List, Input, Button } from 'components'
import { DataView } from 'pages/RequirementOverview'
import { FaRegCalendarAlt } from 'react-icons/fa'
import CandidateOverview from 'components/CadidateOverview'
import { Tab, TabContent, TabList, TabTrigger } from 'components/Tabs'
import ProfileCard from 'components/applicant/card'
import { useMutation, useQuery } from 'react-query'
import usePostRecurtment, {
  applicantList,
  applicationDetail,
} from 'hooks/usePostRecurtment'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import usePostRecurtmentContext from 'context/PostRecurtmentContext'
import CandidateStageDetail from 'components/CandidateStageDetail'
import Spinner from 'Common/spinner'
import SendMessage from 'modals/SendMessage'
import useCandidate from 'hooks/useCandidate'
import WarningModal from 'modals/Warning'
import CopyContact from 'modals/CopyContact'
import NotFound from 'components/NotFound'
const PostRecrutmentOverview = () => {
  const { getApplicantList, getApplicantData, ApplicantApproveOrReject } =
    usePostRecurtment()
  const { sendEmail } = useCandidate()
  const { setCurrentApplicant, currentApplicant } = usePostRecurtmentContext()
  const { postRecurtmentId } = useParams()
  const navigate = useNavigate()
  localStorage.setItem('postRecurtmentId', postRecurtmentId)
  const applicantList = useQuery({
    queryKey: ['applicantList', postRecurtmentId],
    queryFn: () => getApplicantList(postRecurtmentId),
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
  })

  //getting the currentApplicant data
  const { isLoading: currentApplicantLoading, data: applicantData } = useQuery({
    queryKey: ['currentApplicantData', currentApplicant],
    queryFn: () => getApplicantData(currentApplicant),
    enabled: !!currentApplicant,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
      window.location.href = '/postrecrutment'
    },
  })

  const messageHandler = useMutation({
    mutationKey: ['messageHandler'],
    mutationFn: sendEmail,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
  })

  const rejectApplicatHandler = useMutation({
    mutationKey: ['rejectApplicatHandler'],
    mutationFn: ApplicantApproveOrReject,
    onError: (error: any) => {
      toast.error(error?.response?.data?.message)
    },
  })

  const statusHandler = () => {
    if (!currentApplicant?.applicationid) {
      toast.error('select the applicant first')
      return
    }
    const data = {
      application_id: currentApplicant.applicationid,
      stage: 'Post Recruitment',
      approveStatus: 'Rejected',
    }
    toast.promise(rejectApplicatHandler.mutateAsync(data), {
      loading: 'updating the candidate status',
      success: 'candidate status updated successfully',
      error: 'error updating the candidate status',
    })
  }

  const handleCurrentApplicant = (applicantData) => {
    setCurrentApplicant({
      applicationid: applicantData.id,
      candidateid: applicantData.candidateId,
    })
  }
  const emailHandler = (data: {
    content: string
    senderName: string
    senderId: string
  }) => {
    if (!currentApplicant?.applicationid) {
      toast.error('select the applicant first')
      return
    }
    const emailFormat = {
      name: data.senderName,
      email: data.senderId,
      subject: 'Query For the Candidate',
      content: data.content,
    }
    toast.promise(messageHandler.mutateAsync(emailFormat), {
      loading: 'sending the mail',
      error: 'Could not send the email to the candidate',
      success: 'Email sent successfully to the candidate',
    })
  }
  console.log(applicantList.data?.candidates?.length)
  if (currentApplicantLoading) {
    return <Spinner />
  }
  const name =
    applicantData?.candidate?.name ??
    (applicantData?.candidate?.firstname && applicantData?.candidate?.lastname
      ? `${applicantData?.candidate?.firstname} ${applicantData?.candidate?.lastname}`
      : applicantData?.candidate?.firstname ??
        applicantData?.candidate?.lastname ??
        '')

  // console.log("applicantData ", applicantData);
  // console.log("currentApplicant ", currentApplicant);
  // console.log("applicantList ", applicantList.data);

  return (
    <>
      <div className="text-neutral-900 text-xl font-medium font-['Poppins'] uppercase mb-[15px]">
        Post Recruitment
      </div>
      <div className="flex md:flex-col flex-row gap-2.5 items-start justify-between  mx-auto md:px-5 w-full">
        <StatsBox
          className="bg-white-A700 flex flex-row gap-[15px] h-[57px] md:h-auto items-center justify-center sm:px-5 px-[38.61px] py-[6.49px] rounded-[15px] shadow-bs w-auto"
          title="Project ID"
          value={
            applicantList.data?.stats?.project_id?.project_id || 'no data yet'
          }
        />
        <StatsBox
          className="bg-white-A700 flex flex-row gap-[15px] h-[57px] md:h-auto items-center justify-center sm:px-5 px-[38.61px] py-[6.49px] rounded-[15px] shadow-bs w-auto"
          title="Requirement Name"
          value={applicantList.data?.stats?.job_id?.title || 'no data yet'}
        />
        <StatsBox
          className="bg-white-A700 flex flex-row gap-[15px] h-[57px] md:h-auto items-center justify-center sm:px-5 px-[38.61px] py-[6.49px] rounded-[15px] shadow-bs w-auto"
          title="Visa Application"
          value={
            String(applicantList.data?.candidates?.length) || 'no data yet'
          }
        />
        {/* <StatsBox
          className="bg-white-A700 flex flex-row gap-[15px] h-[57px] md:h-auto items-center justify-center sm:px-5 px-[38.61px] py-[6.49px] rounded-[15px] shadow-bs w-auto"
          title="VISA Applied"
          value="03"
        /> */}
      </div>
      <div className="bg-white-A700 flex md:flex-col flex-row md:gap-5 items-start justify-start  mx-auto md:px-5 rounded-[15px] shadow-bs w-full">
        {/* GROUP ONE */}
        <div className="flex flex-col items-start justify-start p-[15px] w-auto">
          <div className="bg-white-A700 border border-gray-200 border-solid flex flex-col items-start justify-start py-2.5 rounded-[15px] shadow-bs w-[313px]">
            <List
              className="flex flex-col items-start w-full font-montserrat"
              orientation="vertical"
            >
              <div className="w-full max-h-[500px] overflow-y-auto">
                {applicantList?.data?.candidates?.map((applicant) => {
                  return (
                    <ProfileCard
                      profile={{
                        label: applicant.candidate_name,
                        desc: applicant.candidate_status || 'Visa Processing',
                        id: applicant.application_id,
                        candidateId: applicant.candidate_id,
                      }}
                      key={applicant.application_id}
                      clickFunction={handleCurrentApplicant}
                      activeClass={
                        applicant?.application_id ==
                          currentApplicant?.applicationid &&
                        'bg-blue-50_01 border-blue-A200 border-r-[3px]'
                      }
                      applicantDetails={applicantData}
                    />
                  )
                })}
              </div>
            </List>
          </div>
        </div>
        {/* GROUP TWO */}
        {currentApplicant ? (
          <div className="flex-1 flex-col gap-2.5 items-start justify-start p-[15px] w-full">
            <div className="bg-blue-A200 flex flex-col gap-2.5 items-start justify-start py-2.5 rounded-[15px] shadow-bs w-full">
              <div className=" w-full  px-5 max-w-[500px] gap-1 my-3 grid ">
                <Text
                  className="w-auto text-lg text-white-A700 capitalize"
                  size="txtPoppinsMedium18"
                >
                  {name}
                </Text>

                <DataView
                  data={applicantData?.position}
                  title="Position applied"
                  textClass="!text-white-A700"
                  dataClass="!text-white-A700"
                />
                <DataView
                  data={
                    applicantData?.candidate?.whatsapp_number
                      ? applicantData?.candidate?.whatsapp_number
                      : applicantData?.candidate?.mobile_number || 'no data yet'
                  }
                  title="Whatsapp Number"
                  textClass="!text-white-A700"
                  dataClass="!text-white-A700"
                />
                <DataView
                  data={applicantData?.candidate?.email || 'no data yet'}
                  title="Mail"
                  textClass="!text-white-A700"
                  dataClass="!text-white-A700"
                />
              </div>
              <div className="flex sm:flex-col flex-row gap-2.5 items-center justify-end px-5 py-2.5 w-full">
                <SendMessage
                  primaryColor="orange"
                  sendId={applicantData?.candidate?.email}
                  candidateName={applicantData?.candidate?.firstname}
                  sendfunction={emailHandler}
                >
                  <Button
                    className="cursor-pointer flex items-center justify-center min-w-[105px] px-[15px] py-[13px] rounded-[10px] bg-white-A700 "
                    leftIcon={
                      <FaRegCalendarAlt className="mx-2 text-gray-400" />
                    }
                  >
                    <div className="font-medium leading-[normal] text-left text-sm text-gray-600_01 capitalize">
                      message
                    </div>
                  </Button>
                </SendMessage>
                <CopyContact number={applicantData?.candidate?.mobile_number}>
                  <Button
                    className="bg-white-A700 cursor-pointer flex items-center justify-center min-w-[165px] px-[15px] py-[13px] rounded-[10px]"
                    leftIcon={
                      <Img
                        className="h-4 mt-[3px] mb-px mr-2.5"
                        src="/images/img_call_gray_600_01.svg"
                        alt="call"
                      />
                    }
                  >
                    <div className="font-medium leading-[normal] text-center text-gray-600_01 text-sm">
                      Contact
                    </div>
                  </Button>
                </CopyContact>
                <WarningModal
                  warningText="Click on this will reject the candidate from this process. Are you sure?"
                  title="Reject Candidate"
                  acceptFunction={statusHandler}
                  disabled={applicantData?.candidate_status === 'Rejected'}
                >
                  <Button
                    className="bg-white-A700 cursor-pointer flex items-center justify-center min-w-[114px] px-[15px] py-[13px] rounded-[10px]"
                    leftIcon={
                      <Img
                        className="mt-[3px] mb-0.5 mr-2.5"
                        src="/images/img_call_gray_600_01_15x16.svg"
                        alt="call"
                      />
                    }
                    disabled={applicantData?.candidate_status === 'Rejected'}
                  >
                    <div className="font-medium leading-[normal] text-center text-gray-600_01 text-sm">
                      Reject
                    </div>
                  </Button>
                </WarningModal>
              </div>
            </div>
            <Tab>
              <TabList wrapClassName="rounded-[18px] shadow-1 px-2 py-2 mt-2">
                <TabTrigger
                  value={0}
                  activeClass="bg-blue-400 text-white-A700"
                  className="px-3 py-2 mb-2 bg-blue-200 rounded-l-md "
                >
                  Candidate Details
                </TabTrigger>
                <TabTrigger
                  value={1}
                  activeClass="bg-blue-400 text-white-A700"
                  className="px-3 py-2 mb-2 bg-blue-200 rounded-r-md"
                >
                  Update Progress
                </TabTrigger>
              </TabList>
              <TabContent value={0}>
                <CandidateOverview
                  loading={currentApplicantLoading}
                  cv={
                    applicantData?.candidate?.documents?.find(
                      (doc) => doc.document_name == 'cv',
                    )?.document_url
                  }
                />
              </TabContent>
              <TabContent value={1}>
                <CandidateStageDetail
                  conuntry={applicantList?.data?.stats?.country}
                  loading={currentApplicantLoading}
                  stages={applicantData?.stages}
                  documents={applicantData?.candidate?.documents}
                  cv={applicantData?.candidate?.cv}
                  flyoutCheckList={applicantData?.flyout_details}
                  applicantDetails={applicantData?.candidate}
                />
              </TabContent>
            </Tab>
            {/* GROUP TWO */}
          </div>
        ) : (
          <div className="grid w-full p-24 place-items-center md:p-7">
            {' '}
            <NotFound
              title="No candidate Found"
              subtitle="Please select the candidate to the see the details and if there is no candidates please go to the post hiring"
            />
            <Button
              className="bg-blue-A200 pl-3 pr-2 pt-2 pb-3 mt-7 rounded-md text-white-A700"
              onClick={() =>
                navigate(
                  `/requirementOverview/${applicantList.data?.stats?.job_id?.id}`,
                )
              }
            >
              Go To Post Hiring<span className="ml-2 text-2xl">&#8599;</span>
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default PostRecrutmentOverview
